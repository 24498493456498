import { Brand } from '~/lib/common/brand'
import ReviewProviderInterface, { Review, ReviewType } from '~/lib/review/ReviewProviderInterface'
import uniStandardReview from '~/static/review/uni-standard.json'
import uniBusinessReview from '~/static/review/uni-business.json'
import fuzStandardReview from '~/static/review/fuz-standard.json'

export const reviewMap: Record<string, Review[]> = {
  'uni-standard': uniStandardReview,
  'uni-business': uniBusinessReview,
  'fuz-standard': fuzStandardReview
} as const;

export default class ReviewProvider implements ReviewProviderInterface {
  private readonly brand: Brand

  private constructor (brand: Brand) {
    this.brand = brand;
  }

  public static forBrand (brand: Brand): ReviewProviderInterface {
    return new ReviewProvider(brand)
  }

  getReviews(type: ReviewType = 'standard'): Review[] {
    return reviewMap[`${this.brand.toLowerCase()}-${type}`]
  }
}