<template>
  <div class="font-sans">
    <navbar />
    <nuxt />
    <page-footer />
  </div>
</template>
<script>
import Navbar from '~/components/layouts/Navbar.vue'
import PageFooter from '~/components/layouts/PageFooter.vue'

export default {
  name: 'Default',
  components: { Navbar, PageFooter },
  head() {
    return {
      link: [
        {
          rel: 'canonical',
          href: `https://${process.env.brand.url}${this.$route.path}`
        }
      ]
    }
  }
}
</script>
