import { extend } from 'vee-validate';
// eslint-disable-next-line camelcase
import { required, email, alpha_spaces, min } from 'vee-validate/dist/rules'

extend('required', required)
extend('email', email)
extend('alpha_spaces', alpha_spaces)
extend('min', min)

extend('phone', value => {
  const result = value.match(/^(?:08|07|03|02|04)?[0-9]{8}$/)
  return result ? true : 'Please provide an Australian phone number'
})