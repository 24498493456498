<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10.057"
    height="21.416"
    viewBox="0 0 10.057 21.416"
  ><path
    class="a"
    d="M196.307,492.445V502.8a.271.271,0,0,0,.273.269h3.887a.271.271,0,0,0,.273-.269V492.276h2.818a.271.271,0,0,0,.271-.247l.271-3.171a.271.271,0,0,0-.272-.293h-3.089v-2.25a.96.96,0,0,1,.965-.955h2.172a.271.271,0,0,0,.273-.269v-3.171a.271.271,0,0,0-.273-.27h-3.67a3.878,3.878,0,0,0-3.9,3.857v3.057h-1.943a.271.271,0,0,0-.273.27v3.171a.271.271,0,0,0,.273.27h1.943Z"
    transform="translate(-194.091 -481.652)"
  /></svg>
</template>

<script>
export default {
  name: 'Facebook'
}
</script>

<style scoped>
.a {
  fill-rule: evenodd;
}
</style>
