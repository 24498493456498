import { RootState } from 'store'
import { ActionTree, MutationTree, GetterTree } from 'vuex'

import { outage } from '~/store/api'
import { Outage, Announcement } from '~/store/interfaces'

export const name = 'announcement'

export const namespaced = true

export interface State {
  outages: Array<Outage | null>
  announcements: Array<Announcement | null>
}

export const state = (): State => ({
  outages: [],
  announcements: []
})

export const getters: GetterTree<State, RootState> = {
  outages: state => state.outages,
  announcements: state => state.announcements
}

export interface Actions<S, R> extends ActionTree<S, R> {}

export const actions: Actions<State, RootState> = {
  async fetchOutages({ state, rootGetters, commit }) {
    try {
      await outage.axios(`${outage.apiUrl}/outages`).then(async response => {
        const outageData = response.data
        const tempOutages = []
        const tempAnnouncements = []
        outageData.map(async os => {
          const osTemp = os
          osTemp.title = os.title
          osTemp.dateStarted = os.started_at
          osTemp.dateResolved = os.resolved_at

          tempOutages.push(osTemp)

          os.announcement.map(async an => {
            const anTemp = an
            anTemp.title = an.title
            anTemp.body = an.body

            if (an.displayWebsite) {
              tempAnnouncements.push(anTemp)
            }
          })
        })
        commit('setOutages', tempOutages)
        commit('setAnnouncements', tempAnnouncements)
      })
    } catch (e) {
      console.log('Error getting outages: ', e)
    }
  },
  async fetchAnnouncements({ state, rootGetters, commit }) {
    try {
      const tempAnnouncements = []
      await outage
        .axios(`${outage.apiUrl}/announcements?display=web`)
        .then(async response => {
          const annData = response.data.data

          annData.map(async an => {
            const anTemp = an
            anTemp.title = an.title
            anTemp.body = an.body

            tempAnnouncements.push(anTemp)
          })
          commit('setAnnouncements', tempAnnouncements)
        })
    } catch (e) {
      console.log('Error getting outages: ', e)
    }
  }
}

export const mutations: MutationTree<State> = {
  setOutages(state: State, outages: Array<Outage>) {
    state.outages = outages
  },

  setAnnouncements(state: State, announcements: Array<Announcement>) {
    state.announcements = announcements
  }
}
