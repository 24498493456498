
import { namespace } from 'vuex-class'
import { Vue, Component } from 'vue-property-decorator'
import * as announcement from '~/store/modules/announcements'

const Announcement = namespace(announcement.name)
@Component({
  data() {
    return {
      siteId: process.env.siteId,
      brand: process.env.brand,
      timerCount: -1,
      ann: []
    }
  },
  watch: {
    announcements: {
      handler(value) {
        this.ann = value
        this.timerCount = 0
      }
    },
    timerCount: {
      handler(value) {
        if (value < this.ann.length - 1) {
          setTimeout(() => {
            // eslint-disable-next-line no-plusplus
            this.timerCount += 1
          }, 5000)
        } else {
          setTimeout(() => {
            this.timerCount = 0
          }, 5000)
        }
      },
      immediate: true
    }
  }
})
export default class NewsTicker extends Vue {
  @Announcement.Getter
  announcements

  @Announcement.Action
  fetchAnnouncements

  showInfoBanner = false

  closeInfoBanner() {
    this.showInfoBanner = false
  }

  // eslint-disable-next-line class-methods-use-this
  async created() {
    await this.fetchAnnouncements()
    if (this.announcements.length > 0) {
      this.showInfoBanner = true
    }
  }
}
