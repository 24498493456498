import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { RootState } from 'store'

export interface State {}

export interface Actions<S, R> extends ActionTree<S, R> {}

export const state = (): State => ({})

export const mutations = {}

export const getters: GetterTree<State, RootState> = {}

export const actions: Actions<State, RootState> = {}

// export const mutations: MutationTree<State> = {}
