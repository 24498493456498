import { Brand } from '~/lib/common/brand'
import { ContactRequest } from '~/lib/client/contact/ContactClientInterface'
import {
  BuildingManagementContactForm,
  BusinessContactForm,
  ContactForm, FibreConnectContactForm, GenericContactForm,
  WhiteLabelContactForm, BuildToRentContactForm
} from '~/lib/contact/ContactForms'

export default class ContactRequestBuilder {
  private readonly brand: Brand

  constructor(brand: Brand) {
    this.brand = brand;
  }

  create(form: ContactForm): ContactRequest {
    return {
      to: this.getTo(form),
      subject: this.getSubject(form),
      message: this.getMessage(form)
    }

  }

  private getTo(form: ContactForm): string {
    if(this.isWhiteLabelContactForm(form) || this.isBuildingManagementContactForm(form) || this.isBuildToRentContactForm(form)) {
      return 'slt@unitiinternet.com'
    }

    if(this.isFibreConnectContactForm(form) || this.isBusinessContactForm(form)) {
      return 'sales@unitiinternet.com'
    }

    switch(this.brand) {
      case 'FUZ':
        return 'support@fuzenet.net.au'
      default:
        return 'support@unitiinternet.com'
    }
  }

  private getSubject(form: ContactForm): string {
    if(this.isWhiteLabelContactForm(form)) {
      return `White Label Contact Form from ${form.business} - ${this.brandCodeToText()}`
    }
    
    if(this.isBuildToRentContactForm(form)) {
      return `Build to Rent Contact Form from ${form.business} - ${this.brandCodeToText()}`
    }

    if(this.isBusinessContactForm(form)) {
      return `Business Contact Form from ${form.business} - ${this.brandCodeToText()}`
    }

    if(this.isBuildingManagementContactForm(form)) {
      return `Uniti Building Management Program Form from ${form.buildingName}`
    }

    if(this.isFibreConnectContactForm(form)) {
      const subject = `FTTP Upgrade Form (${this.brandCodeToText()}) -`;

      return form.isReady ? `${subject} Ready for Upgrade` : `${subject} Planned Upgrade`
    }

    if(this.isGenericContactForm(form)) {
      return form.subject
    }

    return `Contact Form Submission - ${this.brandCodeToText()}`;
  }

  private getMessage(form: ContactForm): string {
    let message = '';

    Object.entries(form).forEach(([key, value]) => {
      message += `${key.toUpperCase()}: ${value}\n`
    });

    return message;
  }

  private isBusinessContactForm(form: ContactForm): form is BusinessContactForm {
    return (form as BusinessContactForm).business !== undefined;
  }

  private isWhiteLabelContactForm(form: ContactForm): form is WhiteLabelContactForm {
    return (form as WhiteLabelContactForm).message !== undefined && (form as WhiteLabelContactForm).business !== undefined;
  }
  
  private isBuildToRentContactForm(form: ContactForm): form is BuildToRentContactForm {
    return (form as BuildToRentContactForm).message !== undefined && (form as BuildToRentContactForm).business !== undefined;
  }

  private isBuildingManagementContactForm(form: ContactForm): form is BuildingManagementContactForm {
    return (form as BuildingManagementContactForm).buildingName !== undefined;
  }

  private isFibreConnectContactForm(form: ContactForm): form is FibreConnectContactForm {
    return (form as FibreConnectContactForm).isReady !== undefined;
  }

  private isGenericContactForm(form: ContactForm): form is GenericContactForm {
    return (form as GenericContactForm).subject !== undefined;
  }

  private brandCodeToText(): string {
    switch(this.brand) {
      case 'FUZ':
        return 'FuzeNet'
      case 'HIS':
        return 'Harbour ISP'
      case 'QUA':
        return 'QuantumNet'
      default:
        return 'Uniti'
    }
  }
}