
import { namespace } from 'vuex-class'
import { Vue, Component } from 'vue-property-decorator'
import Logo from '~/components/Brand/Logo.vue'
import LogoNew from '~/components/Brand/LogoNew.vue'
import PhoneSmall from '~/components/Icons/PhoneSmall.vue'
import NewsTicker from '~/components/Announcements/NewsTicker.vue'
import * as sq from '~/store/modules/sq'
import * as catalog from '~/store/modules/catalog'

const SQ = namespace(sq.name)
const Catalog = namespace(catalog.name)

@Component({
  components: {
    Logo,
    LogoNew,
    PhoneSmall,
    NewsTicker
  },
  data() {
    return {
      siteId: process.env.siteId,
      brand: process.env.brand
    }
  }
})
export default class Navbar extends Vue {
  @SQ.Action
  updateUTMData

  @Catalog.Action
  fetchAllProducts

  @Catalog.Getter
  internetCatalogs

  showMenu = false

  showTech = false

  showMail = false

  toggleMenu() {
    this.showMenu = !this.showMenu
  }

  async created() {
    if (this.$route.query.utm_source) {
      this.updateUTMData({
        utm_source: this.$route.query.utm_source
          ? this.$route.query.utm_source
          : '',
        utm_medium: this.$route.query.utm_medium
          ? this.$route.query.utm_medium
          : '',
        utm_campaign: this.$route.query.utm_campaign
          ? this.$route.query.utm_campaign
          : '',
        utm_content: this.$route.query.utm_content
          ? this.$route.query.utm_content
          : ''
      })
    }
    await this.fetchAllProducts()
  }
}
