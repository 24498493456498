import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { RootState } from 'store'
import { Characteristic, Characteristics } from '~/lib/client/pq/ProductQualificationClientInterface'
import { pq } from '~/store/api'

export const name = 'pq'
export const namespaced = true

export interface State {
  characteristics: Characteristics
}

export const state = (): State => ({
  characteristics: new Map<string, Characteristic>()
})

export interface Actions<S, R> extends ActionTree<S, R> {}

export const actions: Actions<State, RootState> = {
  async getCharacteristicsForExternalId({ commit }, externalId: string) {
    try {
      commit('setCharacteristics', await pq.getCharacteristicsForExternalId(externalId))
    } catch (error) {
      // Do nothing if the call fails
      console.error(error)
    }
  }
}

export const mutations: MutationTree<State> = {
  setCharacteristics(state: State, characteristics: Characteristics) {
    state.characteristics = characteristics
  },
}

export const getters: GetterTree<State, RootState> = {
  characteristics: (state): Characteristics => state.characteristics
}