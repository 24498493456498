import { State } from './root'
import * as root from './root'

import * as creditCard from './modules/creditcard'
import * as faq from './modules/faq'
import * as review from './modules/review'
import * as signup from './modules/signup'
import * as sq from './modules/sq'
import * as phones from './modules/phones'
import * as contact from './modules/contact'
import * as catalog from './modules/catalog'
import * as cart from './modules/cart'
import * as announcement from './modules/announcements'
import * as pq from './modules/pq'
import * as outages from './modules/outages'

export type RootState = root.State

export const state = root.state

export const getters = root.getters

export const mutations = root.mutations

export const actions = root.actions

export const modules = {
  catalog,
  cart,
  creditCard,
  faq,
  review,
  signup,
  sq,
  phones,
  contact,
  announcement,
  outages,
  pq,
}
