/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable prefer-destructuring */
/* eslint-disable dot-notation */
/* eslint-disable no-underscore-dangle */
import { ActionTree, MutationTree, GetterTree } from 'vuex'
import { RootState } from 'store'
import { createHash } from 'crypto';

import {
  Cart,
  CartProduct,
  CartAddress,
  CartCustomer,
  CartCreditCard,
  CartNetwork,
  CartError,
  CartFieldError,
  ShippingAddress,
  Catalog,
  Plan,
  Discount,
  CartPromo,
  CartBankAccount,
  CartCreditCheck,
  CartVoip,
  Addon,
  Network
} from '~/store/interfaces'
import { sq, sqV2, application, api, promotion } from '~/store/api'
import CartMath from '~/lib/CartMath'
import { Carrier } from '../interfaces'
import { PromoError, Promotion as PromosResponse } from '~/lib/promotions/interfaces'
import PromotionWrapper from '~/lib/promotions/PromotionWrapper'

export const name = 'cart'
export interface State {
  cart: Cart,
  promos: PromosResponse[],
  promosWrapper: PromotionWrapper | null,
  promoMatchesActivationFee: [],
}

export const namespaced = true

export const state = (): State => ({
  cart: {
    id: '',
    price: 0,
    location: '',
    technology: '',
    salesPerson: null,
    source: null,
    complete: false,
    dealer: '',
    brand: '',
    notes: null,
    contractTerm: 0,
    preorder: false,
    contractCost: 0,
    products: [
      {
        id: null,
        name: 'Activation Fee',
        price: 0,
        code: null,
        term: 0,
        symbillId: null,
        type: 'activationFee'
      },
      {
        id: null,
        name: 'Plan Product',
        price: 0,
        code: null,
        term: 0,
        symbillId: null,
        type: 'plan'
      }
    ],
    address: {
      id: '',
      address: '',
      building: '',
      carrier: '',
      carrierId: '',
      developmentName: '',
      gnaf: null,
      levelNumber: null,
      levelType: null,
      lotNumber: null,
      network: {
        id: 2,
        name: 'NBN',
        identifier: 'nbn',
        carrierId: '',
        carrierCode: 'OF',
        priority: 6,
        maxDownload: 100,
        maxUpload: 40,
        minDownload: 0,
        minUpload: 0
      },
      ndc: 0,
      ncc: 0,
      postcode: '',
      state: '',
      streetName: '',
      streetNumber: '',
      streetSuffix: '',
      streetType: '',
      suburb: '',
      technology: '',
      topology: '',
      unitNumber: '',
      unitType: '',
      addressConfirmed: false,
      powerType: '',
      buildingHeight: '',
      roofType: '',
      buildingMaterials: '',
      street1: '',
      street2: '',
      ntdLocation: null,
      velocityStatus: null,
      serviceClass: null,
      expectedActivationDate: null,
      isVelocity: false
    },
    customer: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      businessName: '',
      abn: '',
      shippingAddress: {
        postcode: '',
        state: '',
        streetName: '',
        streetNumber: '',
        streetSuffix: '',
        streetType: '',
        suburb: '',
        street1: '',
        street2: ''
      },
      shippingAddressConfirmed: false,
      referralCode: ''
    },
    paymentType: 'credit card',
    creditCard: {
      firstName: '',
      lastName: '',
      number: '',
      expiryMonth: '',
      expiryYear: '',
      cvv: '',
      token: '',
      provider: ''
    },
    bankAccount: {
      customerName: '',
      accountNumber: '',
      bsb: '',
      token: ''
    },
    creditCheck: {
      birthdate: null,
      licenseId: null
    },
    termAccepted: false,
    privacyAccepted: false,
    directDebitAccepted: false,
    csgWaiverAccepted: false,
    suggestedPromo: null,
    filteredPromos: null,
    promoPrices: { promoPrice: null, ongoingPromoPrice: null, ndcDiscount: 0 },
    newService: {
      status: false,
      oldAccountNumber: null
    },
    relocation: {
      status: false,
      oldAccountNumber: null,
      oldAddress: null,
      oldServiceId: null
    },
    partner: null,
    promodeal: null,
    activePromo: null,
    staticIp: false,
    activationDate: null,
    activationDateOption: 'ASAP',
    needsModem: false,
    summaryPromo: null,
    listed: false,
    ticketId: null,
    cartError: {
      error: false,
      fieldErrors: []
    },
    utmData: {},
    suggestedPromoMinContract: 0,
    suggestedPromoMinDownload: 0,
    voipOnly: false,
    memberId: null,
    voipPorting: {
      existingFnn: '',
      phoneProvider: '',
      phoneAccountName: '',
      phoneAccountNumber: ''
    },
    voipPortingAccepted: false,
    voipPortingAgreement: false,
    voipPortingAgreement2: false,      
    coolingOffPeriod: false,
  },
  promos: [],
  promosWrapper: null,
  promoMatchesActivationFee: [],
})

export const getters: GetterTree<State, RootState> = {
  cart: state => state.cart,
  id: state => state.cart.id,
  network: state => state.cart.address.network,
  price: state => state.cart.price,
  location: state => state.cart.location,
  salesPerson: state => state.cart.salesPerson,
  referral: state => state.cart.customer.referralCode,
  source: state => state.cart.source,
  complete: state => state.cart.complete,
  dealer: state => state.cart.dealer,
  brand: state => state.cart.brand,
  notes: state => state.cart.notes,
  products: state => state.cart.products,
  address: state => state.cart.address,
  customer: state => state.cart.customer,
  customerName: state =>
    `${state.cart.customer.firstName} ${state.cart.customer.lastName}`,
  shippingAddressConfirmed: state =>
    state.cart.customer.shippingAddressConfirmed,
  customerShippingAddress: state => state.cart.customer.shippingAddress,
  paymentType: state => state.cart.paymentType,
  creditCard: state => state.cart.creditCard,
  bankAccount: state => state.cart.bankAccount,
  birthdate: state => state.cart.creditCheck.birthdate,
  licenseId: state => state.cart.creditCheck.licenseId,
  plan: state =>
    state.cart.products.filter(p => p && p.type === 'plan').find(e => !!e),
  voicePlan: state => state.cart.products.filter(p => p && p.type === 'voice'),
  term: state => state.cart.contractTerm,
  contract: state =>
    (state.cart.products.filter(p => p.type === 'activationFee') as any).term,
  promo: state => state.cart.activePromo,
  suggestedPromo: state => state.cart.suggestedPromo,
  promoPrice: state => state.cart.promoPrices.promoPrice,
  ongoingPromoPrice: state => state.cart.promoPrices.ongoingPromoPrice,
  ongoingPromoDuration: state => state.cart.promoPrices.ongoingPromoDuration,
  activationFee: state =>
    state.cart.products
      .filter(p => p && p.type === 'activationFee')
      .find(e => !!e),
  ndc: state =>
    state.cart.products.filter(p => p && p.type === 'ndc').find(e => !!e),
  ncc: state => state.cart.address.ncc,
  serviceClass: state => state.cart.address.serviceClass,
  expectedActivationDate: state => state.cart.address.expectedActivationDate,
  isVelocity: state => state.cart.address.isVelocity,
  ntdLocation: state => state.cart.address.ntdLocation,
  shipping: state =>
    state.cart.products.filter(p => p && p.type === 'shippingFee')[0],
  hardware: state => state.cart.products.filter(p => p && p.type === 'hardware'),
  voiceHardware: state =>
    state.cart.products.filter(p => p && p.type === 'voiceHardware'),
  voice: state => state.cart.products.filter(p => p && p.type === 'voice'),
  hideBYO(state, _, rootState, rootGetters) {
    const byo =
      (state.cart.address.network.identifier !== 'wireless' &&
      state.cart.products.find(p => p && p.type === 'voice') &&
      state.cart.products.find(p => p && p.type === 'voice').id !== null)
    return byo
  } ,
  termAccepted: state => state.cart.termAccepted,
  privacyAccepted: state => state.cart.privacyAccepted,
  directDebitAccepted: state => state.cart.directDebitAccepted,
  csgWaiverAccepted: state => state.cart.csgWaiverAccepted,
  addressConfirmed: state => state.cart.address.addressConfirmed,
  newService: state => state.cart.newService.status,
  newServiceAccountNumber: state => state.cart.newService.oldAccountNumber,
  relocation: state => state.cart.relocation.status,
  relocationAccountNumber: state => state.cart.relocation.oldAccountNumber,
  relocationAddress: state => state.cart.relocation.oldAddress,
  relocationOldServiceId: state => state.cart.relocation.oldServiceId,
  ndcDiscount: state => state.cart.promoPrices.ndcDiscount,
  partner: state => state.cart.partner,
  staticIp: state => state.cart.staticIp,
  cartStaticIp: state => state.cart.products.find(p => p && p.name === 'Static IP'),
  activationDate: state => state.cart.activationDate,
  activationDateOption: state => state.cart.activationDateOption,
  needsModem: state => state.cart.needsModem,
  addressPowerType: state => state.cart.address.powerType,
  addressBuildingHeight: state => state.cart.address.buildingHeight,
  addressRoofType: state => state.cart.address.roofType,
  addressBuildingMaterials: state => state.cart.address.buildingMaterials,
  summaryPromo: state => state.cart.summaryPromo,
  listed: state => state.cart.listed,
  ticketId: state => state.cart.ticketId,
  technology: state => state.cart.technology,
  technologyCart: state => state.cart.technology,
  filteredPromos: state => state.cart.filteredPromos,
  cartError: state => state.cart.cartError.error,
  cartErrorFields: state => state.cart.cartError.fieldErrors,
  utmData: state => state.cart.utmData,
  voipPorting: state => state.cart.voipPorting,
  voipPortingAccepted: state => state.cart.voipPortingAccepted,
  monthlyCost(state) {
    return CartMath.getMonthlyCost(state.cart.products)
  },
  promosWrapper: state => state.promosWrapper,
  freeMonthDiscounts(state, getters, rootState, rootGetters) {
    let totalFree = 0

    if (
      state.cart.activePromo &&
      state.cart.products.filter(p => p && p.name === 'ONE-OFF').length
    ) {
      totalFree += state.cart.products
        .filter(p => p && p.type === 'plan')
        .find(e => !!e).price
    }

    if (
      state.cart.activePromo &&
      state.cart.products.filter(p => p && p.name === 'YEAR-OFF').length
    ) {
      totalFree +=
        state.cart.products.filter(p => p && p.type === 'plan').find(e => !!e)
          .price * 12
    }

    if (
      state.cart.activePromo &&
      state.cart.products.filter(p => p && p.name === 'MONTH-OFF').length
    ) {
      totalFree +=
        state.cart.products.filter(p => p && p.type === 'plan').find(e => !!e)
          .price *
        state.cart.products.find(p => p && p.name === 'MONTH-OFF')
          .actionDuration
    }
    return totalFree
  },
  routerDiscountAfter(state, getters, rootState, rootGetters) {
    let tempFeeDiscounts = 0
    if (state.cart.activePromo) {
      if (
        state.cart.products.filter(p => p && p.type === 'hardware').length &&
        state.cart.products.filter(p => p && p.name === 'ROUTER-DISCOUNT')
          .length
      ) {
        const rd = state.cart.products.find(
          p => p && p.name === 'ROUTER-DISCOUNT'
        )
        const hw = state.cart.products.find(p => p && p.type === 'hardware')

        const fh = rootGetters['catalog/filteredHardware']
        if (
          (rd as any).actionApplicableTo &&
          (rd as any).actionApplicableTo.includes(hw.code)
        ) {
          tempFeeDiscounts += hw.price * (rd.price / -100)
        }
        // if discount is on base router only
        else if (
          fh.length > 1 &&
          (rd as any).actionApplicableTo &&
          (rd as any).actionApplicableTo.find(aa =>
            state.cart.products
              .filter(p => p && p.type === 'hardware')[0]
              .code.includes(aa)
          )
        ) {
          const baseRouter = state.promosWrapper.getBaseRouter(rootGetters['catalog/filteredHardware'], (rd as Discount), state.cart.products.find(p => p && p.type === 'hardware'))
          tempFeeDiscounts += CartMath.getBaseRouterDiscount(
            state.cart.products.find(p => p && p.type === 'hardware'),
            baseRouter,
            state.cart.contractTerm,
            state.cart.address.network.carrierCode,
            rd.price
          )
        }
      }
      if (
        state.cart.products.filter(p => p && p.type === 'hardware').length &&
        state.cart.products.filter(p => p && p.name === 'ROUTER-DISCOUNT-AFTER')
          .length
      ) {
        const newRouterPrice =
          state.cart.products.find(p => p && p.type === 'hardware').price -
          tempFeeDiscounts
        const rd =
          state.cart.products.find(p => p && p.name === 'ROUTER-DISCOUNT-AFTER')
            .price / 100
        const fd = parseFloat((newRouterPrice * rd).toFixed(2))
        return Math.abs(fd)
      }
    }
    return 0
  },
  suggestedPromoMinContract: state => state.cart.activePromo ? state.promosWrapper.getPromoMinContract(state.cart.activePromo.code, state.cart.filteredPromos) : 0,
  suggestedPromoMinDownload: state => state.cart.activePromo ? state.promosWrapper.getPromoMinDownload(state.cart.activePromo.code, state.cart.filteredPromos) : 0,
  voipOnly: state => state.cart.voipOnly,
  memberId: state => state.cart.memberId,
  cardFee(state, getters, rootState, rootGetters) {
    if (state.cart.paymentType === 'credit card' && (rootGetters['creditCard/creditCard'])) {
      if ((rootGetters['creditCard/creditCard'].number).startsWith('4') || (rootGetters['creditCard/creditCard'].number).startsWith('5')) {
        return 0.0095
      } if ((rootGetters['creditCard/creditCard'].number).startsWith('3')) {
        return 0.024
      } 
    } if (state.cart.paymentType === 'bank account') {
      return 0.1364
    }
    return 0
  },
  voipPortingAgreement: state => state.cart.voipPortingAgreement,
  voipPortingAgreement2: state => state.cart.voipPortingAgreement2,
  promoMatchesActivationFee: state => state.promoMatchesActivationFee,
  discountsBySpeed(state, getters, rootState, rootGetters) {
    return state.cart.activePromo ? state.promosWrapper.discountsBySpeed(state.cart.activePromo.code, state.cart.filteredPromos, rootGetters['catalog/plans']) : new Map<number, { discount: number, months: number }>()
  },
  coolingOffPeriod: state => state.cart.coolingOffPeriod,
  contractCost(state, getters, rootState, rootGetters) {    
    let freeMonth = 0
    if (state.cart.contractTerm && state.cart.contractTerm > 0) {
      if (state.cart.activePromo && state.cart.activePromo.discounts && state.cart.activePromo.isValid && !state.cart.activePromo.error) {
        state.cart.activePromo.discounts.forEach(discount => {
          if (discount.name === 'ONE-OFF') {
            freeMonth = 1
          }

          if (discount.name === 'YEAR-OFF') {
            freeMonth = 12
          }

          if (discount.name === 'MONTH-OFF') {
            freeMonth = discount.actionDuration
          }
        })
      }
      return CartMath.getMinimumCost(state.cart.products, state.cart.contractTerm, state.cart.activePromo, freeMonth, (state.promosWrapper as PromotionWrapper), rootGetters['catalog/filteredHardware'], rootGetters['sq/primaryNetwork'].carrierCode)
    }
    return state.cart.price
  }
}

export const actions: ActionTree<State, RootState> = {
  async fetchAddressSuggestions({ state, commit }) {
    try {
      const addresses = await sqV2.autocomplete(state.cart.customer.shippingAddress)
      commit('updateAutocompleteSuggestions', addresses)
    } catch (e) {
      console.log(
        'Error: Problem getting autocomplete address from sq: ',
        e.message
      )
    }
  },
  async fetchPromos({ state, commit, rootGetters }) {    
    commit('setOngoingPromoPrice', 0)
    commit('setPromoPrice', 0)

    if (!state.promos.length) {
      await promotion.axios
        .get(`${promotion.apiUrl}/promotions?brand=${process.env.siteId}`)
        .then(response => {
          if (response.data) {
            const promos = response.data

            commit('setPromos', promos)
            commit('createPromotionWrapper', promos)
            
          }
        })
        
    }
  },
  async fetchPromoCode({ state, commit, rootGetters, dispatch }) {
    await commit('setSuggestedPromo', null)
    await commit('setActivePromo', null)
    await commit('setSummaryPromo', null)
    await commit('setOngoingPromoPrice', 0)
    await commit('setPromoPrice', 0)

    let promos = []
    
    if (state.promos.length <= 0 || !state.promosWrapper) {      
      await dispatch('fetchPromos')
    }
    if (state.promosWrapper) {
      
      await commit('setProducts', state.promosWrapper.getCartWithoutPromos(state.cart.products))
      
      const promotionDiscounts = await rootGetters['catalog/promotions']
        

      promos = state.promosWrapper.filteredPromos(promotionDiscounts, state.cart.address, rootGetters['catalog/planGroup'], rootGetters['pq/characteristics'])
    }
    

    if (promos.length) {

      const filteredPromos = promos
      
      await commit('setFilteredPromos', filteredPromos)

      const primaryPromo = state.promosWrapper.getSuggestedPromo(promos, state.cart.partner, state.cart.address, state.cart.promodeal, state.cart.technology)
      
      if (primaryPromo) {
        commit('setSuggestedPromo', primaryPromo)
        commit('setSummaryPromo', primaryPromo.code)
        commit('setActivePromo', primaryPromo)
        await dispatch('checkFreeActivationPromo', primaryPromo.code)
      }
    }
  },
  async checkFreeActivationPromo({ state, rootGetters, commit }, promoCode: String){
    const freeActivationPromos = []

    const activationFees = rootGetters['catalog/activationFees']
    const promoMatches = state.cart.filteredPromos.filter(fp => fp.code === promoCode)

    activationFees.forEach(activationFee => {
      const promo = state.promosWrapper.getPromo(
        promoMatches,
        activationFee
      )
      
      if (promo) {
        freeActivationPromos[activationFee.term] = promo.discounts.some(dd => dd.name === 'FREE-ACTIVATION')
      }

    });

    await commit('setPromoMatchesActivationFee', freeActivationPromos)

  },
  async checkPromoCode({ state, commit, rootGetters }, promoCode: String) {
    await commit('setOngoingPromoPrice', 0)
    await commit('setPromoPrice', 0)
    await commit('setActivePromo', null)

    let promoMatches = null
    
    const promos = state.promosWrapper.filteredPromos(rootGetters['catalog/promotions'], state.cart.address, rootGetters['catalog/planGroup'], rootGetters['pq/characteristics'])
    if (promos) {
      promoMatches = promos.filter(fp => fp.code === promoCode)
    }

    const currentPlan = state.cart.products
      .filter(p => p && p.type === 'plan')
      .find(e => !!e)
    const currentTerm = state.cart.products
      .filter(p => p && p.type === 'activationFee')
      .find(e => !!e)

    let promoIsValid = false
    if (promoMatches && promoMatches.length > 0) {
      const finalPromo = promoMatches[0]

      // get active promo based on selected cart
      if (promoMatches.length > 1) {
        const activePromo = state.promosWrapper.getPromoFromGroup(promoMatches, currentPlan, currentTerm, state.cart.address, state.cart.partner)
        
        // update suggestedPromo if the promo preference has changed
        if (activePromo) {

          commit('setSuggestedPromo', activePromo)
          commit('setSummaryPromo', activePromo.code)
          await commit('setActivePromo', activePromo)
        }
      } else {
        
        await commit('setActivePromo', finalPromo)
      }


      commit('setPromoError', '')

      // check for errors
      if (state.cart.activePromo) {

        const cartErrors: PromoError[] = state.promosWrapper.getPromoErrors(state.cart.activePromo, state.cart.address, (currentPlan as unknown as Plan), currentTerm, rootGetters['pq/characteristics'])

        
        promoIsValid = true
        if(cartErrors.length > 0) {
          let errorStr = ''
          promoIsValid = false
          cartErrors.forEach(err => {errorStr += `${err.description}<br>`})
          await commit('setPromoError', errorStr)
          await commit('setPromoMatchesActivationFee', [])
        }
      }
      

      const currentProducts = state.cart.products.filter(
        p => p && p.type !== 'promotion'
      )

      await commit('setPromoValidity', promoIsValid)
      if (promoIsValid) {

        await commit('setProducts', state.promosWrapper.addPromosToCart(currentProducts, state.cart.activePromo, rootGetters['catalog/hardwares'], state.cart.address.serviceClass))
        let ongoingSavings = state.promosWrapper.getOngoingSavings(state.cart.activePromo, state.cart.products)
        let oneOffSavings = state.promosWrapper.getOneoffSavings(state.cart.activePromo, state.cart.products, currentTerm, state.cart.address, rootGetters['catalog/filteredHardware'])
      
        // discounts computed AFTER
        if (state.cart.activePromo.discounts && state.cart.activePromo.discounts.find(di => di.code && di.code.includes('AFTER'))) {

          ongoingSavings += state.promosWrapper.getOngoingSavingsAfter(state.cart.activePromo, state.cart.products)
          oneOffSavings += state.promosWrapper.getOneoffSavingsAfter(rootGetters['catalog/filteredHardware'], state.cart.products, state.cart.activePromo, state.cart.address)
        }

        await commit('setPromoPrice', oneOffSavings)
        await commit('setOngoingPromoPrice', ongoingSavings)
      }
    }

    if (!promoIsValid) {


      await commit('setPromoValidity', promoIsValid)
      await commit('setOngoingPromoPrice', 0)
      await commit('setProducts', state.promosWrapper.getCartWithoutPromos(state.cart.products))
      await commit('setPromoMatchesActivationFee', [])
    }
  },
  addProduct({ state, commit }, product: CartProduct) {
    const currentProducts = state.cart.products.filter(
      p => p && product && p.type !== product.type
    )

    currentProducts.push(product)

    commit('setProducts', currentProducts)
  },
  async removeProduct({ state, commit }, productType: string) {
    const currentProducts = state.cart.products.filter(
      p => p && p.type !== productType
    )

    commit('setProducts', currentProducts)
  },
  async addNdc({ state, commit, rootGetters }, ndc: number) {
    if (ndc > 0) {
      const ndcProduct = rootGetters['catalog/defaultNDC']

      const currentProducts = state.cart.products.filter(p => p.type !== 'ndc')

      currentProducts.push(ndcProduct)

      commit('setProducts', currentProducts)
      commit('setNdcPrice', ndc)
    } else {
      const currentProducts = state.cart.products.filter(p => p.type !== 'ndc')

      commit('setProducts', currentProducts)
    }
  },
  async addShippingAddress(
    { state, commit },
    shippingAddress: ShippingAddress
  ) {
    commit('setShippingAddress', shippingAddress)
  },
  async addCreditCard({ state, commit }, creditCard: CartCreditCard) {
    commit('setCreditCard', creditCard)
  },
  async updateAddressId({ state, commit }, id: string) {
    commit('setAddressId', id)
  },
  async updateSalesPerson({ state, commit }, salesPerson: string) {
    commit('setSalesPerson', salesPerson)
  },
  async updateTermAccepted({ state, commit }, accepted: boolean) {
    commit('setTermAccepted', accepted)
  },
  async updatePrivacyAccepted({ state, commit }, accepted: boolean) {
    commit('setPrivacyAccepted', accepted)
  },
  async updateDirectDebitAccepted({ state, commit }, accepted: boolean) {
    commit('setDirectDebitAccepted', accepted)
  },
  async updateCSGWaiverAccepted({ state, commit }, accepted: boolean) {
    commit('setCSGWaiverAccepted', accepted)
  },
  async updateLocation({ state, commit }, location: string) {
    commit('setLocation', location)
  },
  async updateTechnology({ state, commit }, technology: string) {
    commit('setTechnology', technology)
  },
  async updateTerm({ state, commit }, term: number) {
    commit('setTerm', term)
  },
  async updateNetwork({ state, commit }, network: CartNetwork) {
    commit('setNetwork', network)
  },
  async updateDealer({ state, commit }, dealer: string) {
    commit(
      'setDealer',
      ['FL', 'SM', 'SMP', 'WIR'].includes(dealer) ? 'NB' : dealer
    )
  },
  async updateCartPrice({ state, commit }, price: number) {
    commit('setPrice', price)
  },
  async updatePromo({ state, commit }, promoValid: boolean) {
    commit('setPromoValidity', promoValid)
  },
  async updatePartner({ state, commit }, partner: string) {
    commit('setPartner', partner)
  },
  async updatePromoDeal({ state, commit }, promo: string) {
    commit('setPromoDeal', promo)
  },
  async updateStaticIp({ state, commit }, staticIp: boolean) {
    commit('setStaticIp', staticIp)
  },
  async updatePreorder({ state, commit }, preorder: boolean) {
    commit('setPreorder', preorder)
  },
  async updateActivationDate({ state, commit }, activationDate: string) {
    commit('setActivationDate', activationDate)
  },
  async updateListed({ state, commit }, listed: boolean) {
    commit('setListed', listed)
  },
  updateRelocationDetails({ state, commit }, contact: string) {
    if (typeof atob !== 'undefined') {
      const data = JSON.parse(atob(contact))

      commit('setCustomerFirstName', data._data.FirstName)
      commit('setCustomerLastName', data._data.LastName)
      commit('setCustomerEmail', data._data.PrimaryEmail)
      commit('setRelocationAccountNumber', data._data.AccountNumber)
      commit('setRelocationAddress', data._data.OldAddress)
      commit('setRelocationOldServiceId', data._data.OldService)
    }
  },
  updateNewServiceDetails({ state, commit }, contact: string) {
    if (typeof atob !== 'undefined') {
      const data = JSON.parse(atob(contact))

      commit('setCustomerFirstName', data._data.FirstName)
      commit('setCustomerLastName', data._data.LastName)
      commit('setCustomerEmail', data._data.PrimaryEmail)
      commit('setNewServiceAccountNumber', data._data.AccountNumber)

      if (state.cart.voipOnly) {
        commit('setRelocationAddress', data._data.OldAddress)
        commit('sq/setLocationId', data._data.SiteId, { root: true })
        commit('sq/setCarrierCode', Carrier[data._data.Carrier], { root: true })
      }
    }
  },
  async recalculateActivationFee({ state, rootGetters, commit, dispatch}) {
    if (
      !rootGetters['sq/isBusiness'] &&
      state.cart.products
        .filter(p => p && p.type === 'activationFee')
        .find(e => !!e)
    ) {
      let currentActivationFee = process.env.siteId === 'hisp' ? 99 : 49
      if (state.cart.dealer !== 'UW') {
        switch (
          state.cart.products
            .filter(p => p && p.type === 'activationFee')
            .find(e => !!e).term
        ) {
          case 0:
            currentActivationFee = 0
            break
          case 12:
            currentActivationFee = 0
            break
          default:
            currentActivationFee = 0
            break
        }

        /* if (
          process.env.siteId === 'hisp' &&
          ['OC', 'LB', 'ON'].includes(state.cart.dealer) &&
          state.cart.products
            .filter(p => p && p.type === 'activationFee')
            .find(e => !!e).term === 0
        ) {
          const currentActivation = state.cart.products
            .filter(p => p && p.type === 'activationFee')
            .find(e => !!e)
          const noLockInFee = {
            ...currentActivation,
            id: currentActivation.id,
            name: 'No lock In Fee',
            description: 'No Lock In Fee',
            price: 99,
            term: 0,
            type: 'nolockin'
          }
          const currentProducts = state.cart.products.filter(
            p => p && p.type !== 'nolockin'
          )
          currentProducts.push(noLockInFee)
          commit('setProducts', currentProducts)
        } else {
          const currentProducts = state.cart.products.filter(
            p => p && p.type !== 'noLockInFee'
          )
          commit('setProducts', currentProducts)
        } */
      } else {
        switch (
          state.cart.products
            .filter(p => p && p.type === 'activationFee')
            .find(e => !!e).term
        ) {
          case 0:
            currentActivationFee = 799.95
            break
          case 12:
            currentActivationFee = 149.95
            break
          default:
            currentActivationFee = 149.95
            break
        }
      }

      if (state.cart.voipOnly) {
        currentActivationFee = 0
      }

      commit('updateActivationFeePrice', currentActivationFee)
    }

    if (rootGetters['sq/isBusiness'] && !state.cart.id) {
      const currentAct = state.cart.products
      .filter(p => p && p.type === 'activationFee')
      .find(e => !!e)
      if (
        currentAct &&
        (!(currentAct as Plan).hardwareMatch.length && (currentAct as Plan).term !== state.cart.contractTerm ||
          ((currentAct as Plan).hardwareMatch &&
            rootGetters["cart/hardware"][0] &&
            !(currentAct as Plan).hardwareMatch.includes(
              rootGetters["cart/hardware"][0].code
            )))
      ) {
        await dispatch(
          "addProduct",
          rootGetters["catalog/defaultActivationFee"]
        );

        await dispatch(
          "updateTerm",
          rootGetters["catalog/defaultActivationFee"].term
        );
      }
    }
  },
  async updateActivationFee({ state, commit }, activationFee: number) {
    commit('setActivationFee', activationFee)
  },
  async updateHardwareFee({ state, commit }, price: number) {
    commit('updateHardwarePrice', price)
  },
  async updatePromoPrice({ state, commit }, promoPrice: number) {
    commit('setPromoPrice', promoPrice)
  },
  async updateOngoingPromoPrice({ state, commit }, promoPrice: number) {
    commit('setOngoingPromoPrice', promoPrice)
  },
  async updateNdcDiscount({ state, commit }, ndcDiscount: number) {
    commit('setNdcDiscount', ndcDiscount)
  },
  async completeCart({ state, commit }) {
    commit('setComplete', true)
  },
  async submitCart({ state, commit, rootGetters }) {
    commit('setBrand', (process.env.brand as any).code)
    const cart = {
      cartTotalPrice: [
        {
          priceType: 'total',
          price: { taxIncludedAmount: { amount: state.cart.price } }
        }
      ],
      relatedParty: [
        {
          name: `${state.cart.customer.firstName} ${
            state.cart.customer.lastName
          }`,
          role: 'Customer'
        },
        { name: state.cart.location, role: 'Location ID' },
        { name: state.cart.technology, role: 'Technology' },
        { name: state.cart.dealer, role: 'Dealer' },
        { name: state.cart.brand, role: 'Brand' },
        { name: state.cart.salesPerson || '', role: 'Sales Person' },
        { name: state.cart.source || 'Website', role: 'Source' }
      ],
      contactMedium: [
        {
          mediumType: 'Address',
          mediumCharacteristic: {
            // eslint-disable-next-line no-nested-ternary
            street1: state.cart.address.streetType
              ? `${state.cart.address.streetNumber} ${
                  state.cart.address.streetName
                } ${state.cart.address.streetType}`
              : state.cart.address.streetNumber && state.cart.address.streetName
                ? `${state.cart.address.streetNumber} ${
                    state.cart.address.streetName
                  }`
                : state.cart.address.street1,
            street2: state.cart.address.street2
              ? state.cart.address.street2
              : '',
            city: state.cart.address.suburb,
            postcode: state.cart.address.postcode,
            stateOrProvince: state.cart.address.state
          }
        },
        {
          mediumType: 'Firstname',
          mediumCharacteristic: { firstname: state.cart.customer.firstName }
        },
        {
          mediumType: 'Lastname',
          mediumCharacteristic: { lastname: state.cart.customer.lastName }
        },
        {
          mediumType: 'Email',
          mediumCharacteristic: { emailAddress: state.cart.customer.email }
        },
        {
          mediumType: 'Phone',
          mediumCharacteristic: { phoneNumber: state.cart.customer.phone }
        }
      ],
      cartItem: []
    }

    if (
      state.cart.address.id
    ) {
      ;(cart.contactMedium as any).push({
        mediumType: 'Sq',
        mediumCharacteristic: {
          id: state.cart.address.id
        }
      })
    }

    if (state.cart.id) {
      cart['id'] = state.cart.id
    }

    if (state.cart.salesPerson != null || localStorage.getItem('salesPerson')) {
      cart.relatedParty.push({
        name: state.cart.salesPerson
          ? state.cart.salesPerson
          : localStorage.getItem('salesPerson'),
        role: 'Sales Person'
      })
    }

    
    if (state.cart.creditCheck.birthdate != null) {
      cart.relatedParty.push({
        name: state.cart.creditCheck.birthdate,
        role: 'Birthdate'
      })
    }

    if (state.cart.creditCheck.licenseId != null) {
      cart.relatedParty.push({
        name: state.cart.creditCheck.licenseId,
        role: 'License Id'
      })
    }

    const contractCost = rootGetters['cart/contractCost']
    if (contractCost >= 0){
      cart.relatedParty.push({
        name:  String(contractCost),
        role: 'Contract Cost'
      })
    }

    if (Object.keys(state.cart.utmData).length) {
      cart.relatedParty.push({
        name: JSON.stringify(state.cart.utmData),
        role: 'UTM Data'
      })
    }
    
    if (rootGetters['cart/voicePlan'].length && state.cart.voipPortingAccepted && state.cart.voipPorting.existingFnn !== '') {
      if (state.cart.voipPorting.existingFnn) {
        cart.relatedParty.push({
          name: state.cart.voipPorting.existingFnn,
          role: 'ExistingFnn'
        })
      }
      if (state.cart.voipPorting.phoneAccountName) {
        cart.relatedParty.push({
          name: state.cart.voipPorting.phoneAccountName,
          role: 'PhoneAccountName'
        })
      }
      if (state.cart.voipPorting.phoneAccountNumber) {
        cart.relatedParty.push({
          name: state.cart.voipPorting.phoneAccountNumber,
          role: 'PhoneAccountNumber'
        })
      }
      if (state.cart.voipPorting.phoneProvider) {
        cart.relatedParty.push({
          name: state.cart.voipPorting.phoneProvider,
          role: 'PhoneProvider'
        })
      }
    }

    if (state.cart.address.unitNumber) {
      cart.contactMedium.forEach(cm => {
        if (cm.mediumType === 'Address') {
          cm.mediumCharacteristic.street2 = `${state.cart.address.unitType} ${
            state.cart.address.unitNumber
          }`
        }
      })
    }

    if (state.cart.address.levelNumber) {
      cart.contactMedium.forEach(cm => {
        if (cm.mediumType === 'Address') {
          cm.mediumCharacteristic.street2 = `${state.cart.address.levelType} ${
            state.cart.address.levelNumber
          }`
        }
      })
    }

    if (state.cart.customer.businessName) {
      ;(cart.contactMedium as any).push({
        mediumType: 'BusinessName',
        mediumCharacteristic: {
          socialNetworkId: state.cart.customer.businessName
        }
      })
    }

    if (state.cart.customer.abn) {
      ;(cart.contactMedium as any).push({
        mediumtype: 'Abn',
        mediumCharacteristic: {
          socialNetworkId: state.cart.customer.abn
        }
      })
    }

    if (state.cart.creditCard.firstName && state.cart.creditCard.lastName) {
      ;(cart.contactMedium as any).push({
        mediumtype: 'CardName',
        mediumCharacteristic: {
          socialNetworkId: `${state.cart.creditCard.firstName} ${
            state.cart.creditCard.lastName
          }`
        }
      })
    }

    if (state.cart.creditCard.expiryMonth && state.cart.creditCard.expiryYear) {
      ;(cart.contactMedium as any).push({
        mediumtype: 'CardExpiry',
        mediumCharacteristic: {
          socialNetworkId: `${state.cart.creditCard.expiryMonth}/${
            state.cart.creditCard.expiryYear
          }`
        }
      })
    }

    if (state.cart.creditCard.token) {
      ;(cart.contactMedium as any).push({
        mediumtype: 'CardToken',
        mediumCharacteristic: {
          socialNetworkId: `${state.cart.creditCard.token}`
        }
      })
    }
    
    if (state.cart.bankAccount.customerName) {
      ;(cart.contactMedium as any).push({
        mediumtype: 'BankAccountName',
        mediumCharacteristic: {
          socialNetworkId: `${state.cart.bankAccount.customerName}`
        }
      })
    }

    if (state.cart.bankAccount.accountNumber) {
      ;(cart.contactMedium as any).push({
        mediumtype: 'BankAccountNumber',
        mediumCharacteristic: {
          socialNetworkId: `${state.cart.bankAccount.accountNumber}`
        }
      })
    }
    
    if (state.cart.bankAccount.bsb) {
      ;(cart.contactMedium as any).push({
        mediumtype: 'BankAccountBsb',
        mediumCharacteristic: {
          socialNetworkId: `${state.cart.bankAccount.bsb}`
        }
      })
    }
    
    if (state.cart.bankAccount.token) {
      ;(cart.contactMedium as any).push({
        mediumtype: 'BankAccountToken',
        mediumCharacteristic: {
          socialNetworkId: `${state.cart.bankAccount.token}`
        }
      })
    }


    if (state.cart.customer.referralCode) {
      ;(cart.contactMedium as any).push({
        mediumType: 'ReferralCode',
        mediumCharacteristic: {
          socialNetworkId: state.cart.customer.referralCode
        }
      })
    }

    if (state.cart.address.powerType) {
      ;(cart.contactMedium as any).push({
        mediumtype: 'PowerType',
        mediumCharacteristic: {
          socialNetworkId: state.cart.address.powerType
        }
      })
    }

    if (state.cart.address.buildingHeight) {
      ;(cart.contactMedium as any).push({
        mediumtype: 'BuildingHeight',
        mediumCharacteristic: {
          socialNetworkId: state.cart.address.buildingHeight
        }
      })
    }

    if (state.cart.address.roofType) {
      ;(cart.contactMedium as any).push({
        mediumtype: 'RoofType',
        mediumCharacteristic: {
          socialNetworkId: state.cart.address.roofType
        }
      })
    }

    if (state.cart.address.buildingMaterials) {
      ;(cart.contactMedium as any).push({
        mediumtype: 'BuildingMaterials',
        mediumCharacteristic: {
          socialNetworkId: state.cart.address.buildingMaterials
        }
      })
    }

    if (state.cart.customer.shippingAddressConfirmed) {
      ;(cart.contactMedium as any).push({
        mediumType: 'ShippingAddress',
        mediumCharacteristic: {
          // eslint-disable-next-line no-nested-ternary
          street1: state.cart.customer.shippingAddress.streetType
            ? `${state.cart.customer.shippingAddress.streetNumber} ${
                state.cart.customer.shippingAddress.streetName
              } ${state.cart.customer.shippingAddress.streetType}`
            : state.cart.customer.shippingAddress.streetNumber &&
              state.cart.customer.shippingAddress.streetName
              ? `${state.cart.customer.shippingAddress.streetNumber} ${
                  state.cart.customer.shippingAddress.streetName
                }`
              : state.cart.customer.shippingAddress.street1,
          street2: state.cart.customer.shippingAddress.street2
            ? state.cart.customer.shippingAddress.street2
            : '',
          city: state.cart.customer.shippingAddress.suburb,
          postcode: state.cart.customer.shippingAddress.postcode,
          stateOrProvince: state.cart.customer.shippingAddress.state
        }
      })
    }

    if (state.cart.complete) {
      ;(cart.relatedParty as any).push({
        name: '1',
        role: 'Complete'
      })
    }

    if (state.cart.staticIp) {
      ;(cart.relatedParty as any).push({
        name: '1',
        role: 'StaticIp'
      })
    }

    if (state.cart.preorder) {
      ;(cart.relatedParty as any).push({
        name: '1',
        role: 'PreOrder'
      })
    }

    if (state.cart.listed) {
      ;(cart.relatedParty as any).push({
        name: '1',
        role: 'Listed'
      })
    }

    if (state.cart.ticketId) {
      ;(cart.relatedParty as any).push({
        name: state.cart.ticketId,
        role: 'Ticket ID'
      })
    }

    if (state.cart.relocation.status) {
      ;(cart.relatedParty as any).push({
        name: '1',
        role: 'Relocation'
      })
      ;(cart.relatedParty as any).push({
        name: state.cart.relocation.oldAccountNumber.toString(),
        role: 'RelocationAccountNumber'
      })
    }

    if (state.cart.newService.status || state.cart.voipOnly) {
      ;(cart.relatedParty as any).push({
        name: state.cart.newService.oldAccountNumber.toString(),
        role: 'RelocationAccountNumber'
      })
    }

    if (state.cart.partner) {
      ;(cart.relatedParty as any).push({
        name: state.cart.partner,
        role: 'Partner'
      })
    }

    if (state.cart.activationDate) {
      ;(cart.relatedParty as any).push({
        name:
          state.cart.activationDate === 'ASAP'
            ? new Date()
            : state.cart.activationDate,
        role: 'ActivationDate'
      })
    }

    if (state.cart.relocation.oldServiceId) {
      ;(cart.relatedParty as any).push({
        name: state.cart.relocation.oldServiceId.toString() || '',
        role: 'OldServiceId'
      })
    }

    if (state.cart.memberId) {
      ;(cart.relatedParty as any).push({
        name: state.cart.memberId || '',
        role: 'MemberId'
      })
    }
    
    if (state.cart.coolingOffPeriod) {
      ;(cart.relatedParty as any).push({
        name: 'true',
        role: 'HasCoolingOffPeriod'
      })
    }

    // if address has service class
    const serviceClass = rootGetters['sq/address'].serviceClass
    if (serviceClass) {
      ;(cart.relatedParty as any).push({
        name: (serviceClass === null) ? serviceClass : serviceClass.toString(),
        role: 'ServiceClass'
      })
    }

    if (state.cart.products.length) {
      let newPlanPrice = state.cart.products.find(p => p.type === 'plan')
        ? state.cart.products.find(p => p.type === 'plan').price
        : 0

      state.cart.products.map(p => {
        // if relocation, set plan term
        if (state.cart.relocation.status && p.type === 'plan') {
          commit('setTerm', 0)
        }

        if (p.symbillId || p.name === 'Static IP') {
          let pr = p.price
          
          if (p.name === 'Static IP') {
            p.symbillId = '12345'
          }

          if (p.name === 'New Connection Charge' && rootGetters['sq/ncc'] > 0) {
            pr = rootGetters['sq/ncc']
          }


          // set one-off price (free month promo) to plan price
          if (p.name === 'ONE-OFF') {
            pr =
              0 -
              state.cart.products.filter(p => p.type === 'plan').find(e => !!e)
                .price
          }

          if (p.name === 'YEAR-OFF' || p.name === 'MONTH-OFF') {
            pr =
              0 -
              state.cart.products.filter(p => p.type === 'plan').find(e => !!e)
                .price
          }

          // if the promo is % discount
          if (p.name === 'PERCENT') {
            pr =
              (p.price / 100) *
              state.cart.products.filter(p => p.type === 'plan').find(e => !!e)
                .price
          }

          if (p.name === 'RECURRING') {
            newPlanPrice += p.price
          }

          if (p.name === 'FREE-ROUTER') {
            const baseRouter = state.promosWrapper.getBaseRouter(rootGetters['catalog/filteredHardware'], (p as Discount), state.cart.products.find(p => p && p.type === 'hardware'))

            if (baseRouter) {
              pr =
                0 -
                CartMath.getFreeRouterDiscount(
                  state.cart.products.filter(
                    p => p && p.type === 'hardware'
                  )[0],
                  baseRouter,
                  state.cart.contractTerm,
                  state.cart.address.network.carrierCode
                )
            }
          }

          if (p.name === 'ROUTER-DISCOUNT') {
            const baseRouter = state.promosWrapper.getBaseRouter(rootGetters['catalog/filteredHardware'], (p as Discount), state.cart.products.find(p => p && p.type === 'hardware'))
            pr =
              0 -
              CartMath.getBaseRouterDiscount(
                state.cart.products.filter(p => p && p.type === 'hardware')[0],
                baseRouter,
                state.cart.contractTerm,
                state.cart.address.network.carrierCode,
                p.price
              )
          }

          if (p.type === 'activationFee') {
            const freeActivation = state.cart.products
              .filter(p => p.name === 'FREE-ACTIVATION')
              .find(e => !!e)
            if (state.cart.relocation.status || freeActivation) {
              pr = 0
            }

            if (state.cart.voipOnly) {
              pr = 0
              p.term = 0
            }
          }

          let pcode = p.code
          if (p.type === 'promotion') {
            pcode = state.cart.activePromo.code
          }

          if (p.actionDuration) {
            p.term = p.actionDuration
          }

          const product = {
            itemPrice: [
              {
                price: {
                  taxIncludedAmount: {
                    amount: pr
                  }
                }
              }
            ],
            product: {
              name: p.name,
              productSerialNumber: pcode || null,
              productTerm: [
                {
                  duration: {
                    quantity: p.term ? p.term : 0
                  }
                }
              ],
              characteristic: []
            }
          }

          product.product.characteristic.push({
            name: 'symbillId',
            value: p.symbillId
          })

          product.product.characteristic.push({
            name: 'type',
            value: p.type
          })          

          product.product.characteristic.push({
            name: 'group',
            value: p.catalogGroup
          })


          if (p.actionStart) {
            product.product.characteristic.push({
              name: 'startMonth',
              value: p.actionStart
            })
          }

          cart.cartItem.push(product)
        }

        return p
      })

      cart.cartItem.map(ci => {
        if (ci.product.name === 'ROUTER-DISCOUNT-AFTER') {
          ci.itemPrice[0].price.taxIncludedAmount.amount =
            0 - rootGetters['cart/routerDiscountAfter']
        }

        if (ci.product.name === 'PERCENT-AFTER') {
          ci.itemPrice[0].price.taxIncludedAmount.amount = parseFloat(
            (
              newPlanPrice *
              (ci.itemPrice[0].price.taxIncludedAmount.amount / 100)
            ).toFixed(2)
          )

          if (rootGetters['cart/voice'].length) {
            ci.itemPrice[0].price.taxIncludedAmount.amount += parseFloat(
              (
                rootGetters['cart/voice'][0].price *
                (ci.itemPrice[0].price.taxIncludedAmount.amount / 100)
              ).toFixed(2)
            )
          }
        }

        return ci
      })
    }

    if (state.cart.id) {
      // Update Cart
      await application.axios
        .put(`${application.apiUrl}/api/v1/carts/${state.cart.id}`, cart)
        .then(response => {
          commit('setCartId', response.data.data.id)
        })
    } else {
      // Create Cart
      await application.axios
        .post(`${application.apiUrl}/api/v1/carts`, cart)
        .then(response => {
          commit('setCartId', response.data.data.id)
        })
    }
  },
  async fetchCart({ state, rootGetters, commit }, cartId: string) {
    return application.axios
      .get(`${application.apiUrl}/api/v1/carts/${cartId}`)
      .then(async response => {
        if (response.data.data.id) {
          commit('setCartId', response.data.data.id)

          if (response.data.data.cartTotalPrice[0]) {
            commit(
              'setPrice',
              response.data.data.cartTotalPrice[0].price.taxIncludedAmount
                .amount
            )
          }

          await Promise.all(response.data.data.relatedParty.map(async r => {
            if (r.role === 'Customer') {
              const customerName = r.name.split(' ')

              const cartCustomer = {
                firstName: customerName[0],
                lastName: customerName[1],
                email: '',
                phone: '',
                businessName: '',
                abn: '',
                shippingAddress: null,
                shippingAddressConfirmed: false,
                referralCode: null
              }

              await Promise.all(response.data.data.contactMedium.map(async c => {
                if (c.mediumType === 'Email') {
                  cartCustomer.email = c.mediumCharacteristic.emailAddress
                }

                if (c.mediumType === 'Phone') {
                  cartCustomer.phone = c.mediumCharacteristic.phoneNumber
                }

                if (c.mediumType === 'BusinessName') {
                  cartCustomer.businessName =
                    c.mediumCharacteristic.socialNetworkId
                }

                if (c.mediumType === 'Abn') {
                  cartCustomer.abn = c.mediumCharacteristic.socialNetworkId
                }

                if (c.mediumType === 'ReferralCode') {
                  cartCustomer.referralCode =
                    c.mediumCharacteristic.socialNetworkId
                }

                if (c.mediumType === 'PowerType') {
                  commit(
                    'setAddressPowerType',
                    c.mediumCharacteristic.socialNetworkId
                  )
                }

                if (c.mediumType === 'BuildingHeight') {
                  commit(
                    'setAddressBuildingHeight',
                    c.mediumCharacteristic.socialNetworkId
                  )
                }

                if (c.mediumType === 'RoofType') {
                  commit('setRoofType', c.mediumCharacteristic.socialNetworkId)
                }

                if (c.mediumType === 'BuildingMaterials') {
                  commit(
                    'setBuildingMaterials',
                    c.mediumCharacteristic.socialNetworkId
                  )
                }

                if (c.mediumType === 'ShippingAddress') {
                  cartCustomer.shippingAddress = {
                    postcode: c.mediumCharacteristic.postcode,
                    state: c.mediumCharacteristic.stateOrProvince,
                    streetName: '',
                    streetNumber: '',
                    streetSuffix: '',
                    streetType: '',
                    suburb: c.mediumCharacteristic.suburb,
                    street1: c.mediumCharacteristic.street1,
                    street2: c.mediumCharacteristic.street2
                  }
                  cartCustomer.shippingAddressConfirmed = true
                }

                if (c.mediumType === 'Sq') {
                  const carrier = response.data.data.relatedParty.filter(
                    r => r.role === 'Dealer'
                  )[0].name
                  const carrierId = response.data.data.relatedParty.filter(
                    r => r.role === 'Location ID'
                  )[0].name

                  const serviceQualification = await sqV2.sqCarrier(
                    carrier,
                    carrierId
                  )

                  const resp = {
                    data: {
                      data: serviceQualification as any
                    }
                  }
                  let currentNetworks = rootGetters['sq/defaultNetworks']
                  let nbNetworkCarrier
                  if (
                    resp.data.data.carrier === 'NB' ||
                    resp.data.data.carrier === 'OF'
                  ) {
                    switch (response.data.data.relatedParty.filter(
                      r => r.role === 'Technology'
                    )[0].name) {
                      case 'WIR':
                        resp.data.data.carrier = 'WIR'
                        nbNetworkCarrier = 'WIR'
                        break
                      case 'SAT':
                        resp.data.data.carrier = 'SM'
                        nbNetworkCarrier = 'SM'
                        break
                      case 'SMP':
                        resp.data.data.carrier = 'SMP'
                        nbNetworkCarrier = 'SMP'
                        break
                      default:
                        nbNetworkCarrier = 'NB'
                        break
                    }
                  }

                  currentNetworks = currentNetworks.filter(
                    n =>
                      n.carrierCode ===
                      (nbNetworkCarrier || resp.data.data.carrier)
                  )
                  resp.data.data.network = {
                    id: currentNetworks[0].id,
                    name: currentNetworks[0].name,
                    identifier: currentNetworks[0].identifier,
                    carrierCode: nbNetworkCarrier || resp.data.data.carrier,
                    carrierId: resp.data.data.carrier_id,
                    priority: currentNetworks[0].priority,
                    maxDownload: resp.data.data.max_download,
                    maxUpload: resp.data.data.max_upload,
                    minDownload: resp.data.data.min_download,
                    minUpload: resp.data.data.min_upload
                  }

                  resp.data.data.powerType = state.cart.address.powerType
                  resp.data.data.buildingHeight =
                    state.cart.address.buildingHeight
                  resp.data.data.roofType = state.cart.address.roofType
                  resp.data.data.buildingMaterials =
                    state.cart.address.buildingMaterials

                  await commit('setAddress', resp.data.data)
                  commit('sq/setServiceQualification', [resp.data.data], {
                    root: true
                  })
                } else if (
                  !response.data.data.contactMedium.filter(
                    c => c.mediumType === 'Sq'
                  ).length &&
                  c.mediumType === 'Address'
                ) {
                  let cartNetwork

                  if (
                    rootGetters['sq/defaultNetworks'].filter(
                      dn =>
                        dn.carrierCode ===
                        response.data.data.relatedParty
                          .filter(rp => rp.role === 'Dealer')
                          .find(e => !!e).name
                    ).length
                  ) {
                    cartNetwork = rootGetters['sq/defaultNetworks'].filter(
                      dn =>
                        dn.carrierCode ===
                        response.data.data.relatedParty
                          .filter(rp => rp.role === 'Dealer')
                          .find(e => !!e).name
                    )
                  }

                  if (
                    response.data.data.relatedParty.filter(
                      rp => rp.role === 'Dealer'
                    ).length &&
                    response.data.data.relatedParty.filter(
                      rp => rp.role === 'Technology'
                    ).length
                  ) {
                    if (
                      response.data.data.relatedParty
                        .filter(rp => rp.role === 'Technology')
                        .find(e => !!e).name === 'SAT'
                    ) {
                      cartNetwork = rootGetters['sq/defaultNetworks'].filter(
                        dn => dn.carrierCode === 'SM'
                      )
                    }
                    if (
                      response.data.data.relatedParty
                        .filter(rp => rp.role === 'Technology')
                        .find(e => !!e).name === 'SMP'
                    ) {
                      cartNetwork = rootGetters['sq/defaultNetworks'].filter(
                        dn => dn.carrierCode === 'SMP'
                      )
                    }

                    if (
                      response.data.data.relatedParty
                        .filter(rp => rp.role === 'Technology')
                        .find(e => !!e).name === 'WIR'
                    ) {
                      cartNetwork = rootGetters['sq/defaultNetworks'].filter(
                        dn => dn.carrierCode === 'WIR'
                      )
                    }
                  }

                  const address = {
                    id: null,
                    address: `${
                      c.mediumCharacteristic.street2
                        ? c.mediumCharacteristic.street2
                        : ''
                    } ${
                      c.mediumCharacteristic.street1
                        ? c.mediumCharacteristic.street1
                        : ''
                    }, ${
                      c.mediumCharacteristic.city
                        ? c.mediumCharacteristic.city
                        : ''
                    }, ${
                      c.mediumCharacteristic.stateOrProvince
                        ? c.mediumCharacteristic.stateOrProvince
                        : ''
                    }, ${
                      c.mediumCharacteristic.postcode
                        ? c.mediumCharacteristic.postcode
                        : ''
                    }`,
                    street1: c.mediumCharacteristic.street1
                      ? c.mediumCharacteristic.street1
                      : '',
                    street2: c.mediumCharacteristic.street2
                      ? c.mediumCharacteristic.street2
                      : '',
                    addressConfirmed: null,
                    building: null,
                    carrier: response.data.data.relatedParty
                      .filter(rp => rp.role === 'Dealer')
                      .find(e => !!e).name,
                    carrierId: '',
                    developmentName: null,
                    gnaf: null,
                    levelNumber: null,
                    levelType: null,
                    ndc: null,
                    ncc: null,
                    serviceClass: null,
                    expectedActivationDate: null,
                    isVelocity: false,
                    network: cartNetwork[0],
                    postcode: c.mediumCharacteristic.postcode,
                    state: c.mediumCharacteristic.stateOrProvince,
                    streetName: '',
                    streetSuffix: '',
                    streetType: '',
                    suburb: c.mediumCharacteristic.city,
                    unitNumber: '',
                    unitType: '',
                    technology: '',
                    topology: '',
                    powerType: state.cart.address.powerType,
                    buildingHeight: state.cart.address.buildingHeight,
                    roofType: state.cart.address.roofType,
                    buildingMaterials: state.cart.address.buildingMaterials
                  }
                  await commit('setAddress', address)
                  commit('sq/updatePrimaryNetwork', address.network, {
                    root: true
                  })
                  commit(
                    'sq/setServiceQualificationAddressString',
                    address.address,
                    { root: true }
                  )
                  commit('sq/setNetworks', cartNetwork, { root: true })
                }
              }))

              commit('setCustomer', cartCustomer)
            }

            if (r.role === 'Location ID') {
              commit('setLocation', r.name)
              commit(
                'sq/setLocations',
                [
                  {
                    networkIdentifier: state.cart.dealer,
                    locationId: r.name,
                    ndc: '',
                    technology: state.cart.technology,
                    isAvailable: true,
                    canProvision: true
                  }
                ],
                { root: true }
              )
            }

            if (r.role === 'Technology') {
              commit('setTechnology', r.name)
            }

            if (r.role === 'Relocation') {
              commit('setRelocation', true)
            }

            if (r.role === 'RelocationAccountNumber') {
              commit('setRelocationAccountNumber', r.name)
            }

            if (r.role === 'Dealer') {
              commit('setDealer', r.name)
            }

            if (r.role === 'Brand') {
              commit('setBrand', r.name)
            }

            if (r.role === 'Ticket ID') {
              commit('setTicketId', r.name)
            }

            if (r.role === 'ActivationDate') {
              commit('setActivationDate', r.name)
            }

            if (r.role === 'Source') {
              commit('setSource', r.name)
            }

            if (r.role === 'Listed') {
              commit('setListed', r.name)
            }

            if (r.role === 'StaticIp') {
              commit('setStaticIp', true)
            }

            if (r.role === 'Partner') {
              commit('setPartner', r.name)
            }

            if (r.role === 'Sales Person') {
              commit('setSalesPerson', r.name)
            }

            if (r.role === 'PreOrder') {
              commit('setPreorder', true)
            }

            if (r.role === 'MemberId') {
              commit('setMemberId', r.name)
            }

            if (r.role === 'ExistingFNN') {              
              commit('setVoipPortingFnn', r.name)
            }
            
            if (r.role === 'PhoneProvider') {              
              commit('setVoipPortingProvider', r.name)
            }
            if (r.role === 'PhoneAccountName') {              
              commit('setVoipPortingAccountName', r.name)
            }
            if (r.role === 'PhoneAccountNumber') {              
              commit('setVoipPortingAccountNumber', r.name)
            }
            if (r.role === 'HasCoolingOffPeriod') {              
              commit('setCoolingOffPeriod', true)
            }
            if (r.role === 'License Id') {
              commit('setLicenseId', r.name)
            }
            if (r.role === 'Birthdate') {
              commit('setBirthdate', r.name)
            }
          }))

          let actFee = null
          let aPromo
          
          if (response.data.data.cartItem.length) {
            const currentProducts = []
            response.data.data.cartItem.forEach(async p => {
              const symbillId = (p.product.characteristic
                .find(c => c.name === 'symbillId') ? p.product.characteristic
                .find(c => c.name === 'symbillId').value : 0).toString()
              const type = p.product.characteristic
                .filter(c => c.name === 'type')
                .find(e => !!e).value
              
              const startMonth = p.product.characteristic
                .find(c => c.name === 'startMonth') ? p.product.characteristic
                .find(c => c.name === 'startMonth').value : null

              const group = p.product.characteristic
              .find(c => c.name === 'group') ? p.product.characteristic
              .find(c => c.name === 'group').value : ''

              let currentProduct = null

              if (type === 'plan') {
                currentProduct = rootGetters['catalog/plans']
                  .filter(p => p.symbillId === symbillId)
                  .find(e => !!e)
                
                  commit('sq/setIsBusiness', group.includes('Business'), {root: true})
                  commit('catalog/setPlanGroup', group, {root: true})
              }

              if (type === 'hardware') {
                currentProduct = rootGetters['catalog/hardwares']
                  .filter(p => p.symbillId === symbillId)
                  .find(e => !!e)
              }

              if (type === 'voice') {
                currentProduct = rootGetters['catalog/voicePlans']
                  .filter(p => p.symbillId === symbillId)
                  .find(e => !!e)
              }

              if (type === 'ndc') {
                currentProduct = rootGetters['catalog/ndc']
                  .filter(p => p.symbillId === symbillId)
                  .find(e => !!e)
              }

              if (type === 'activationFee') {
                currentProduct = rootGetters['catalog/allFees']
                  .filter(p => p.symbillId === symbillId)
                  .find(e => !!e)
                actFee = currentProduct
              }

              if (type === 'contractFee') {
                currentProduct = rootGetters['catalog/contractFee']
                  .filter(p => p.symbillId === symbillId)
                  .find(e => !!e)
              }

              if (type === 'promotion' && p.product.productSerialNumber !== 'FREE-ACTIVATION') {
                currentProduct = rootGetters['catalog/promotions']
                  .filter(p => p.symbillId === symbillId )
                  .find(e => !!e)
                
                // FREE-ACTIVATION SKU is the default if added manually via a rule promo, don't set it as main promo
                aPromo = p.product.productSerialNumber
                commit('setSummaryPromo', aPromo)
              }
              
              if (type === 'addon') {
                currentProduct = rootGetters['catalog/addOns']
                  .filter(p => p.name === "Static IP")
                  .find(e => !!e)
              }
              
              if (type === 'shippingFee') {
                currentProduct = rootGetters['catalog/shippingFee']

                aPromo = p.product.productSerialNumber
              }
              
              if (currentProduct && startMonth) {
                currentProduct.actionStart = startMonth
              }

              if (currentProduct) {
                currentProducts.push(currentProduct)
              }
            })
            
            commit('setProducts', currentProducts)
            console.log(currentProducts)
            if (actFee) {
              commit('setTerm', actFee.term)
            }
          }
          
          commit('setPromoDeal', aPromo)

        }
      })
    
  },
  async setPromoAfterCartFetch({ state, commit, dispatch}) {
    await dispatch('fetchPromoCode')
    let activePromo = state.cart.activePromo

    if (state.cart.promodeal) {
      activePromo = state.cart.filteredPromos && state.cart.filteredPromos
      .filter(pr => pr.code === state.cart.promodeal)
      .find(e => !!e)    
    }
    
    if (activePromo) {
      await commit('setSummaryPromo', activePromo.code)
      await dispatch('checkPromoCode', activePromo.code)
    }
  },
  async updateCartError({ state, commit }, cartError: boolean) {
    commit('setCartError', cartError)
  },
  async addCartFieldError({ state, commit }, fieldError: CartFieldError) {
    commit('setCartFieldError', fieldError)
  },
  async removeFieldError({ state, commit }, fieldError: string) {
    commit('removeCartFieldError', fieldError)
  },
  async validateBuildingInformation({ state, commit, dispatch }) {
    if (!state.cart.address.buildingHeight) {
      dispatch('addCartFieldError', {
        errorMessage: 'Please select a building height',
        fieldId: 'buildingHeight'
      })
    } else {
      dispatch('removeFieldError', 'buildingHeight')
    }

    if (!state.cart.address.buildingMaterials) {
      dispatch('addCartFieldError', {
        errorMessage: 'Please select a building materials option',
        fieldId: 'buildingMaterials'
      })
    } else {
      dispatch('removeFieldError', 'buildingMaterials')
    }

    if (!state.cart.address.roofType) {
      dispatch('addCartFieldError', {
        errorMessage: 'Please select a roof type',
        fieldId: 'roofType'
      })
    } else {
      dispatch('removeFieldError', 'roofType')
    }

    if (!state.cart.address.powerType) {
      dispatch('addCartFieldError', {
        errorMessage: 'Please select a power type option',
        fieldId: 'powerType'
      })
    } else {
      dispatch('removeFieldError', 'powerType')
    }

    dispatch('updateCartError', state.cart.cartError.fieldErrors.length > 0)
  }, 
  async addProductAddOn({ state, commit }, product: CartProduct) {
    const currentProducts = state.cart.products.filter(
      p => p && p.name !== product.name
    )

    currentProducts.push(product)

    commit('setProducts', currentProducts)
  },
  async removeProductAddOn({ state, commit }, addonName: string) {
    const currentProducts = state.cart.products.filter(
      p => p.name !== addonName
    )

    commit('setProducts', currentProducts)
  }
}

export const mutations: MutationTree<State> = {
  setUTMData(state: State, utmData: object) {
    state.cart.utmData = utmData
  },
  setCartId(state: State, id: string) {
    state.cart.id = id
  },
  setPrice(state: State, price: number) {
    state.cart.price = price
  },
  setLocation(state: State, location: string) {
    state.cart.location = location
  },
  setTechnology(state: State, technology: string) {
    state.cart.technology = technology
  },
  setSalesPerson(state: State, salesPerson: string) {
    state.cart.salesPerson = salesPerson
  },
  setTicketId(state: State, ticketId: string) {
    state.cart.ticketId = ticketId
  },
  setSource(state: State, source: string) {
    state.cart.source = source
  },
  setComplete(state: State, complete: boolean) {
    // Push CONTRACT_SIGNED
    this.$gtm.pushEvent({
      event: 'CONTRACT_SIGNED',
      plan: state.cart.products.filter(p => p.type === 'plan')[0].name,
      contract: state.cart.products.filter(p => p.type === 'plan')[0].term,
      network: state.cart.dealer,
      order_id: state.cart.id,
      location_id: state.cart.address.carrierId,      
      email: state.cart.customer.email
    })    
    
    // Push SALE
    this.$gtm.pushEvent({ event: 'SALE' })    
    
    this.$gtm.pushEvent({
      event: 'purchase', 
      transaction_id: state.cart.address.carrierId,
      value: state.cart.products.filter(p => p.type === 'plan')[0].price,
      coupon: state.cart.activePromo ? state.cart.activePromo.code : '',
      items: [{
        item_id: state.cart.products.filter(p => p.type === 'plan')[0].name,
        item_name: state.cart.products.filter(p => p.type === 'plan')[0].name,
      }],
      currency: 'AUD',
      location_id: state.cart.address.carrierId,      
      email: state.cart.customer.email ? createHash("sha256").update(state.cart.customer.email).digest("hex") : '',
    })
    
    state.cart.complete = complete
  },
  setDealer(state: State, dealer: string) {
    state.cart.dealer = dealer
  },
  setBrand(state: State, brand: string) {
    state.cart.brand = brand
  },
  setNotes(state: State, notes: string) {
    state.cart.notes = notes
  },
  setProducts(state: State, products: CartProduct[]) {
    state.cart.products = products
  },
  setCustomer(state: State, customer: CartCustomer) {
    state.cart.customer = customer
  },
  setSummaryPromo(state: State, summaryPromo: string) {
    state.cart.summaryPromo = summaryPromo
  },
  setFilteredPromos(state: State, filteredPromos: any) {
    state.cart.filteredPromos = filteredPromos
  },
  setAddress(state: State, address: any) {
    state.cart.address = {
      id: address.id,
      address: address.address,
      building: address.building,
      carrier: address.carrier,
      carrierId: address.carrier_id,
      developmentName: address.development_name,
      gnaf: address.gnaf,
      levelNumber: address.level_number,
      levelType: address.level_type,
      lotNumber: address.lot_number,
      network: {
        carrierCode: address.carrier,
        carrierId: address.carrier_id,
        id: address.network.id,
        identifier: address.network.identifier,
        maxDownload: address.max_download,
        maxUpload: address.max_upload,
        minDownload: address.min_download,
        minUpload: address.min_upload,
        name: address.network.name,
        priority: address.network.priority
      },
      ndc: address.ndc,
      ncc: address.ncc,
      postcode: address.postcode,
      state: address.state,
      streetName: address.street_name,
      streetNumber: address.street_number,
      streetSuffix: address.street_suffix,
      streetType: address.street_type,
      suburb: address.suburb,
      technology: address.technology,
      topology: address.topology,
      unitNumber: address.unit_number,
      unitType: address.unit_type,
      addressConfirmed: false,
      powerType: address.powerType,
      buildingHeight: address.buildingHeight,
      roofType: address.roofType,
      buildingMaterials: address.buildingMaterials,
      street1: address.street1,
      street2: address.street2,
      ntdLocation: address.ntd_location,
      velocityStatus: address.velocity_status,
      serviceClass: address.service_class,
      expectedActivationDate: address.expected_activation_date,
      isVelocity: address.is_velocity
    }
  },
  setAddressId(state: State, id: string) {
    state.cart.address.id = id
  },
  setAddressPowerType(state: State, powerType: string) {
    state.cart.address.powerType = powerType
  },
  setAddressBuildingHeight(state: State, buildingHeight: string) {
    state.cart.address.buildingHeight = buildingHeight
  },
  setRoofType(state: State, roofType: string) {
    state.cart.address.roofType = roofType
  },
  setBuildingMaterials(state: State, buildingMaterials: string) {
    state.cart.address.buildingMaterials = buildingMaterials
  },
  setNetwork(state: State, network: any) {
    state.cart.address.network = network
  },
  setCreditCard(state: State, creditCard: CartCreditCard) {
    // state.cart.creditCard = creditCard
    const x = 'x'
    x.repeat(creditCard.number.length - 4)
    const number = x + creditCard.number.substr(-4)
    const cardExp = creditCard.expiryYear

    state.cart.creditCard = {
      firstName: creditCard.firstName,
      lastName: creditCard.lastName,
      number,
      expiryMonth: creditCard.expiryMonth,
      expiryYear: cardExp,
      cvv: '',
      token: creditCard.token,
      provider: creditCard.provider
    }
  },
  setTerm(state: State, term: number) {
    state.cart.contractTerm = term
    state.cart.products.forEach(pr => {
      if (pr && pr.type === 'plan') {
        pr.term = term
      }
    })
  },
  setPromoValidity(state: State, promoValid: boolean) {
    if (state.cart.activePromo) {
      state.cart.activePromo.isValid = promoValid
    }

    // if (!promoValid) {
    //   const currentProducts = state.cart.products.filter(
    //     p => p.type !== 'promotion' && p.name !== 'PROMOTION'
    //   )

    //   state.cart.products = currentProducts
    // }
  },
  setPromoError(state: State, error: string) {
    if (state.cart.activePromo) {
      state.cart.activePromo.error = error
    }
  },
  setActivationFee(state: State, activationFee: number) {
    const currentActivationFee = state.cart.products
      .filter(p => p.type === 'activationFee')
      .find(e => !!e)

    currentActivationFee.price = activationFee
    currentActivationFee.term = state.cart.products
      .filter(p => p.type === 'activationFee')
      .find(e => !!e).term
  },
  updateActivationFeePrice(state: State, activationFee: number) {
    state.cart.products
      .filter(p => p.type === 'activationFee')
      .find(e => !!e).price = activationFee
  },
  updateHardwarePrice(state: State, hardwarePrice: number) {
    state.cart.products
      .filter(p => p.type === 'hardware')
      .find(e => !!e).price = hardwarePrice
  },
  setTermAccepted(state: State, accepted: boolean) {
    state.cart.termAccepted = accepted
  },
  setPrivacyAccepted(state: State, accepted: boolean) {
    state.cart.privacyAccepted = accepted
  },
  setDirectDebitAccepted(state: State, accepted: boolean) {
    state.cart.directDebitAccepted = accepted
  },
  setCSGWaiverAccepted(state: State, accepted: boolean) {
    state.cart.csgWaiverAccepted = accepted
  },
  setCustomerFirstName(state: State, customerFirstName: string) {
    state.cart.customer.firstName = customerFirstName.trim()
  },
  setCustomerLastName(state: State, customerLastName: string) {
    state.cart.customer.lastName = customerLastName.trim()
  },
  setCustomerEmail(state: State, customerEmail: string) {
    state.cart.customer.email = customerEmail
  },
  setCustomerPhone(state: State, customerPhone: string) {
    state.cart.customer.phone = customerPhone
  },
  setCustomerBusinessName(state: State, customerBusinessName: string) {
    state.cart.customer.businessName = customerBusinessName
  },
  setShippingAddress(state: State, shippingAddress: any) {
    state.cart.customer.shippingAddress = shippingAddress
  },
  setCustomerShippingAddress(
    state: State,
    customerShippingAddress: ShippingAddress
  ) {
    state.cart.customer.shippingAddress = customerShippingAddress
  },
  setReferralCode(state: State, referralCode: string) {
    state.cart.customer.referralCode = referralCode
  },
  setCustomerABN(state: State, customerABN: string) {
    state.cart.customer.abn = customerABN
  },
  setAddressConfirmed(state: State, addressConfirmed: boolean) {
    state.cart.address.addressConfirmed = addressConfirmed
  },
  setShippingAddressConfirmed(state: State, shippingAddressConfirmed: boolean) {
    state.cart.customer.shippingAddressConfirmed = shippingAddressConfirmed
  },
  setSuggestedPromo(state: State, suggestedPromo: object) {
    state.cart.suggestedPromo = suggestedPromo
  },
  setActivePromo(state: State, aPromo: object) {
    state.cart.activePromo = aPromo
  },
  setPromoPrice(state: State, promoPrice: number) {
    state.cart.promoPrices.promoPrice = promoPrice
  },
  setOngoingPromoPrice(state: State, promoPrice: number) {
    state.cart.promoPrices.ongoingPromoPrice = promoPrice
  },
  setActivationDate(state: State, activationDate: string) {
    state.cart.activationDate = activationDate
  },
  setActivationDateOption(state: State, activationDateOption: string) {
    state.cart.activationDateOption = activationDateOption
  },
  setNdcDiscount(state: State, ndcDiscount: number) {
    state.cart.promoPrices.ndcDiscount = ndcDiscount
  },
  setNdcPrice(state: State, ndcPrice: number) {
    const currentNdcProduct = state.cart.products
      .filter(p => p.type === 'ndc')
      .find(e => !!e)

    currentNdcProduct.price = ndcPrice
  },
  setPartner(state: State, partner: string) {
    state.cart.partner = partner
  },
  setPromoDeal(state: State, promo: string) {
    state.cart.promodeal = promo
  },
  setRelocation(state: State, relocation: boolean) {
    state.cart.relocation.status = relocation
  },
  setNewService(state: State, merge: boolean) {
    state.cart.newService.status = merge
  },
  setRelocationAccountNumber(state: State, relocationAccountNumber: string) {
    state.cart.relocation.oldAccountNumber = relocationAccountNumber
  },
  setRelocationAddress(state: State, relocationAddress: string) {
    state.cart.relocation.oldAddress = relocationAddress
  },
  setRelocationOldServiceId(state: State, relocationOldServiceId: string) {
    state.cart.relocation.oldServiceId = relocationOldServiceId
  },
  setNewServiceAccountNumber(state: State, nsAccountNumber: string) {
    state.cart.newService.oldAccountNumber = nsAccountNumber
  },
  setStaticIp(state: State, staticIp: boolean) {
    state.cart.staticIp = staticIp
  },
  setPreorder(state: State, preorder: boolean) {
    state.cart.preorder = preorder
  },
  setNeedsModem(state: State, need: boolean) {
    state.cart.needsModem = need
  },
  setListed(state: State, listed: boolean) {
    state.cart.listed = listed
  },
  setContractSigned(state: State) {
    this.$gtm.pushEvent({
      event: 'CONTRACT_SIGNED',
      plan: state.cart.products.filter(p => p.type === 'plan')[0].name,
      contract: state.cart.products.filter(p => p.type === 'plan')[0].term,
      network: state.cart.dealer
    })
  },
  complete() {
    this.$gtm.pushEvent({ event: 'SALE' })
  },
  websiteSale() {
    this.$gtm.pushEvent({ event: 'WEBSITE_SALE' })
  },
  confirmSignUp(state: State) {
    let eventTag
    switch (state.cart.partner) {
      case 'benergy':
        eventTag = 'SIGN_UP_BENERGY'
        break
      case 'iselect':
        eventTag = 'SIGN_UP_ISELECT'
        break
      default:
        eventTag = 'SIGN_UP'
    }
    this.$gtm.pushEvent({
      event: eventTag,
      plan: state.cart.products.filter(p => p.type === 'plan')[0].name,
      contract: state.cart.products.filter(p => p.type === 'plan')[0].term,
      network: state.cart.dealer,
      location_id: state.cart.address.carrierId
    })
  },
  confirmSignUpBenergy(state: State) {
    this.$gtm.pushEvent({
      event: 'SIGN_UP_BENERGY',
      plan: state.cart.products.filter(p => p.type === 'plan')[0].name,
      contract: state.cart.products.filter(p => p.type === 'plan')[0].term,
      network: state.cart.dealer
    })
  },
  confirmContactDetails(state: State) {
    this.$gtm.pushEvent({
      event: 'CONTACT_DETAILS',
      plan: state.cart.products.filter(p => p.type === 'plan')[0].name,
      contract: state.cart.products.filter(p => p.type === 'activationFee')[0]
        ? state.cart.products.filter(p => p.type === 'plan')[0].term
        : state.cart.products.filter(p => p.type === 'plan')[0].term,
      network: state.cart.dealer,
      location_id: state.cart.address.carrierId,      
      email: state.cart.customer.email
    })
  },
  setCartError(state: State, cartError: boolean) {
    state.cart.cartError.error = cartError
  },
  setCartFieldError(state: State, fieldError: CartFieldError) {
    if (
      !state.cart.cartError.fieldErrors.some(
        fe => fe.fieldId === fieldError.fieldId
      )
    )
      state.cart.cartError.fieldErrors.push(fieldError)
  },
  removeCartFieldError(state: State, fieldError: string) {
    const cartFieldErrors = state.cart.cartError.fieldErrors.filter(
      p => p.fieldId !== fieldError
    )

    state.cart.cartError.fieldErrors = cartFieldErrors
  },
  setSuggestedPromoMinContract(state: State, minContract: number) {
    state.cart.suggestedPromoMinContract = minContract
  },
  setSuggestedPromoMinDownload(state: State, minDownload: number) {
    state.cart.suggestedPromoMinDownload = minDownload
  },
  setVoipOnly(state: State, voipOrder: boolean) {
    state.cart.voipOnly = voipOrder
  },
  setMemberId(state: State, memberId: string) {
    state.cart.memberId = memberId
  },
  setPromos(state: State, promotions: PromosResponse[]) {
    state.promos = promotions
  },
  createPromotionWrapper(state: State, promos: PromosResponse[]) {
    state.promosWrapper = new PromotionWrapper(promos)
  },
  setPaymentType(state: State, paymentType: string) {
    state.cart.paymentType = paymentType
  },
  setBankAccountName(state: State, name: string) {
    state.cart.bankAccount.customerName = name
  },
  setBankAccountNumber(state: State, accountNumber: string) {
    state.cart.bankAccount.accountNumber = accountNumber
  },
  setBankAccountBsb(state: State, bsb: string) {
    state.cart.bankAccount.bsb = bsb
  },
  setBankAccountToken(state: State, token: string) {
    state.cart.bankAccount.token = token
  },
  setBirthdate(state: State, birthdate: string) {
    state.cart.creditCheck.birthdate = birthdate
  },
  setLicenseId(state: State, licenseId: string) {
    state.cart.creditCheck.licenseId = licenseId
  },
  setVoipPortingFnn(state: State, fnn: string) {
    state.cart.voipPorting.existingFnn = fnn
  },
  setVoipPortingProvider(state: State, provider: string) {
    state.cart.voipPorting.phoneProvider = provider
  },
  setVoipPortingAccountName(state: State, acct: string) {
    state.cart.voipPorting.phoneAccountName = acct
  },
  setVoipPortingAccountNumber(state: State, num: string) {
    state.cart.voipPorting.phoneAccountNumber = num
  },
  setVoipPortingAccepted(state: State, accept: boolean) {
    state.cart.voipPortingAccepted = accept
  },
  setVoipPortingAgreement(state: State, accept: boolean) {
    state.cart.voipPortingAgreement = accept
  },
  setVoipPortingAgreement2(state: State, accept: boolean) {
    state.cart.voipPortingAgreement2 = accept
  },
  setPromoMatchesActivationFee(state: State, freeActivationPromos: []) {
    state.promoMatchesActivationFee = freeActivationPromos
  },
  setCoolingOffPeriod(state: State, coolingOffPeriod: boolean) {
    state.cart.coolingOffPeriod = coolingOffPeriod
  }
}
