import { Catalog as CatalogResponse, ProductOffering } from '~/lib/catalog/interfaces'
import { Catalog, Hardware, Addon, Plan } from '~/store/interfaces'

const HARDWARE_MAP = new Map<string, string[]>([
  ['NF18MESH', ['nf18acv-cloud.png']],
  ['NF18MESH-01', ['nf18acv-cloud.png', 'ns-01-mesh.png']],
  ['NF18MESH-02', ['nf18acv-cloud.png', 'ns-01-mesh.png', 'ns-01-mesh.png']],
  ['NL1901ACV', ['nl19acv.png']],
  ['NOKIA-5G', ['nokia-5g-gateway.png']],
  ['NOKIA-MESH', ['nokia-beacon.png']],
  ['NOKIA-MESH-01', ['nokia-beacon.png', 'nokia-01.png']],
  ['NOKIA-MESH-02', ['nokia-beacon.png', 'nokia-01.png', 'nokia-01.png']],
  ['NOKIA-BEACON2', ['nokia-beacon-2.png']],
  ['NOKIA-BEACON2-01', ['nokia-beacon-2.png', 'nokia-beacon-2-sm.png']],
  ['NOKIA-BEACON2-02', ['nokia-beacon-2.png', 'nokia-beacon-2-sm.png', 'nokia-beacon-2-sm.png']],
  ['NOKIA-BEACON3', ['nokia-beacon-31.png']],
  ['NOKIA-BEACONG6', ['nokia-beacon-g6.png']],

]);

export default class CatalogWrapper {
  private catalogs: CatalogResponse[]

  private networkPriorities: { name: string, priority: number }[]

  private carrierCodes: string[]

  constructor(catalogs: CatalogResponse[], networkPriorities: { name: string, priority: number }[], carrierCodes: string[] ) {
    this.catalogs = catalogs
    this.networkPriorities = networkPriorities
    this.carrierCodes = carrierCodes
  }

  public internet(): Catalog[] {
    return this.catalogs
      .filter(catalog => catalog.category.name === 'internet')
      .filter(catalog => this.carrierCodes.includes(catalog.name))
      .sort(
        (a, b) =>
          this.networkPriorities.find(network => network.name === a.name).priority -
          this.networkPriorities.find(network => network.name === b.name).priority
      )
  }

  public plans(group?: string, planGroup?: string): Plan[] {
    let plans = []

    // Repeat the catalog filter because the internet() method returns the `Catalog` type that doesn't
    // actually reflect the correct structure of the API
    this.catalogs
      .filter(catalog => catalog.category.name === 'internet')
      .sort(
        (a, b) =>
          this.networkPriorities.find(network => network.name === a.name).priority -
          this.networkPriorities.find(network => network.name === b.name).priority
      )
      .map(async catalog => {
        catalog.category.productOffering
          .filter(productOffering => productOffering.isSellable)
          .map(productOffering => {
            const plan: Plan = {
              ...productOffering,
              type: 'plan',
              displayName: productOffering.name,
              catalogGroup: catalog.catalogGroup,
              identifier: catalog.description.replace(/\s/g, '').toLowerCase(),
              price: productOffering.productOfferingPrice[0].price.amount,
              carrierCode: catalog.name,
              activationFee: 0,
              businessFee: 0,
              suite: undefined,
              term: 0,
              download: this.getValue('Download', productOffering) as number,
              upload: this.getValue('Upload', productOffering) as number,
              quota: this.getValue('Quota', productOffering) as number,
              quotaOffPeak: this.getValue('QuotaOffPeak', productOffering) as number,
              quotaPeak: this.getValue('QuotaPeak', productOffering) as number,
              symbillId: this.getValue('SymbillId', productOffering).toString(),
              typicalEveningSpeed: this.getValue('TypicalEveningSpeed', productOffering) as number
            }

            return plans.push(plan)
          })
      })
    
    if (group) {      
      plans = plans.filter(catalog => catalog.catalogGroup.includes(group))
    }
  
    if (planGroup) {
      plans = plans.filter(pl => pl.catalogGroup === planGroup)
    }

    return plans.sort((a, b) => a.download - b.download)
  }

  public voicePlans(): Catalog[] {
    const plans = []

    this.catalogs
      .filter(catalog => catalog.category.name === 'voice')
      .map(async catalog => {
        catalog.category.productOffering
          .map(productOffering => {
            const plan: Plan = {
              type: 'voice',
              ...productOffering,
              symbillId: this.getValue('SymbillId', productOffering).toString(),
              name: productOffering.name,
              code: productOffering.name,
              price: productOffering.productOfferingPrice[0].price.amount,
              // Required members on type
              activationFee: 0, businessFee: 0, carrierCode: '', download: 0, quota: 0, suite: undefined, upload: 0
            }

            return plans.push(plan)
          })
      })

    return plans.sort((a, b) => a.price - b.price)
  }

  public allFees(): Catalog[] {
    
    const fees = []
    let type = null

    this.catalogs
      .filter(catalog => catalog.category.name === 'fee')
      .map(async catalog => {
        catalog.category.productOffering
          .filter(productOffering => productOffering.isSellable)
          .map(productOffering => {
            const typeCode = this.getValue('Code', productOffering)

            switch (typeCode) {
              case 'ACTIVATION-FEE':
                type = 'activationFee'
                break
              case 'CONTRACT-FEE':
                type = 'contractFee'
                break
              case 'SHIPPING':
                type = 'shippingFee'
                break
              case 'NDC':
                type = 'ndc'
                break
              case 'NCC':
                type = 'ncc'
                break
              default:
                throw new Error(`A product type code of ${typeCode} is not supported`)
            }
            const fee: Plan = {
              symbillId: this.getValue('SymbillId', productOffering).toString(),
              ...productOffering,
              name: productOffering.name,
              catalogGroup: catalog.catalogGroup,
              code: this.getValue('Code', productOffering).toString(),
              price: productOffering.productOfferingPrice[0].price.amount,
              term: (this.getValue('Term', productOffering) as number),
              type,
              hardwareMatch: this.getValue('HardwareGroup', productOffering) as string[],
              activationFee: 0, businessFee: 0, carrierCode: '', download: 0, quota: 0, suite: undefined, upload: 0
            }

            return fees.push(fee)
          })
      })
    
    return fees
  }

  public fees(group: string = 'Residential', planGroup?: string, hardware?: Catalog): Catalog[] {
    let fees = []
    let type = null

    this.catalogs
      .filter(catalog => catalog.category.name === 'fee')
      .filter(catalog => catalog.catalogGroup.includes(group))
      .map(async catalog => {
        catalog.category.productOffering
          .filter(productOffering => productOffering.isSellable)
          .map(productOffering => {
            const typeCode = this.getValue('Code', productOffering)

            switch (typeCode) {
              case 'ACTIVATION-FEE':
                type = 'activationFee'
                break
              case 'CONTRACT-FEE':
                type = 'contractFee'
                break
              case 'SHIPPING':
                type = 'shippingFee'
                break
              case 'NDC':
                type = 'ndc'
                break
              case 'NCC':
                type = 'ncc'
                break
              default:
                throw new Error(`A product type code of ${typeCode} is not supported`)
            }
            const fee: Plan = {
              symbillId: this.getValue('SymbillId', productOffering).toString(),
              ...productOffering,
              name: productOffering.name,
              catalogGroup: catalog.catalogGroup,
              code: this.getValue('Code', productOffering).toString(),
              price: productOffering.productOfferingPrice[0].price.amount,
              term: (this.getValue('Term', productOffering) as number),
              type,
              hardwareMatch: this.getValue('HardwareGroup', productOffering) as string[],
              activationFee: 0, businessFee: 0, carrierCode: '', download: 0, quota: 0, suite: undefined, upload: 0
            }

            return fees.push(fee)
          })
      })

    if (planGroup) {
      
      fees = fees.filter(catalog => catalog.catalogGroup === planGroup)
    }
    if (group.includes('Business')) {      
      if (hardware) {
        fees = fees.filter(catalog => catalog.hardwareMatch.includes((hardware as unknown as Hardware).code))  
      } if (hardware === undefined) {        
        fees = fees.filter(catalog => catalog.hardwareMatch.length < 1)
      }   
    }

    return fees.sort((a, b) => a.term - b.term)
  }

  public promotions(): Catalog[] {
    const promotions = []

    this.catalogs
      .filter(catalog => catalog.category.name === 'promotion')
      .map(async catalog => {
        catalog.category.productOffering
          .filter(productOffering => productOffering.isSellable)
          .map(productOffering => {
            const promotion: Plan = {
              ...productOffering,
              name: productOffering.name,
              symbillId: this.getValue('SymbillId', productOffering).toString(),
              code: this.getValue('Code', productOffering).toString(),
              price: productOffering.productOfferingPrice[0].price.amount,
              type: 'promotion',
              id: productOffering.id,
              activationFee: 0, businessFee: 0, carrierCode: '', download: 0, quota: 0, suite: undefined, upload: 0
            }

            return promotions.push(promotion)
          })
      })

    return promotions.sort((a, b) => a.price - b.price)
  }

  public hardware(group?: string): Hardware[] {
    let hardware = []

    this.catalogs
      .filter(catalog => catalog.category.name === 'hardware')
      .map(async catalog => {
        catalog.category.productOffering
          .filter(productOffering => productOffering.isSellable)
          .map(productOffering => {
            const code = this.getValue('Code', productOffering).toString()

            const image = HARDWARE_MAP.get(code) ?? ['nf18acv-cloud.png']

            const item: Hardware = {
              ...productOffering,
              symbillId: this.getValue('SymbillId', productOffering).toString(),
              name: productOffering.name,
              code,
              catalogGroup: catalog.catalogGroup,
              price: productOffering.productOfferingPrice[0].price.amount,
              orderId: this.getValue('OrderId', productOffering).toString(),
              image,
              type: 'hardware',
              activationFee: 0, carrierCode: '', suite: undefined, displayName: '', term: 0
            }

            return hardware.push(item)
          })
      })

    if (group) {      
      hardware = hardware.filter(catalog => catalog.catalogGroup.includes(group))
    }
    return hardware
      .sort((a, b) => a.price - b.price)
      .sort((a, b) => a.orderId - b.orderId)
  }

  public shippingFee(): Catalog | null {
    return this.fees().find(catalog => (catalog as any).type === 'shippingFee') ?? null
  }

  public contractFee(): Catalog[] {
    return this.fees().filter(catalog => (catalog as any).type === 'contractFee')
  }

  public activationFees(group: string = 'Residential', planGroup?: string, hardware?: Catalog): Catalog[] {
    return this.fees(group, planGroup, hardware)
      .filter(catalog => (catalog as any).type === 'activationFee')
      
  }

  public defaultActivationFee(group: string = 'Residential', planGroup?: string, hardware?: Catalog): Catalog | null {
    return this.fees(group, planGroup, hardware).filter(catalog => (catalog as any).code === 'ACTIVATION-FEE')[0] ?? null
  }

  public defaultNdc(): Catalog | null {
    return this.fees().find(catalog => (catalog as any).code === 'NDC') ?? null
  }

  public defaultNcc(): Catalog | null {
    return this.fees().find(catalog => (catalog as any).code === 'NCC') ?? null
  }

  public ndc(): Catalog[] {
    return this.fees().filter(catalog => (catalog as any).type === 'ndc')
  }

  public addOns(): Catalog[] {
    const addOns = []

    this.catalogs
      .filter(catalog => catalog.category.name === 'addon')
      .map(async catalog => {
        catalog.category.productOffering
          .map(productOffering => {
            const addon: Addon = {
              type: 'addon',
              ...productOffering,
              name: productOffering.name,
              code: productOffering.name,
              price: productOffering.productOfferingPrice[0].price.amount,
              recurrence: this.getValue('Recurrence', productOffering).toString()
            }

            return addOns.push(addon)
          })
      })
    return addOns.sort((a, b) => a.price - b.price)
  } 

  public staticIp(): Catalog {    
    return this.addOns().find(catalog => (catalog as any).name === 'Static IP')
  }
  

  public hardwareDescriptionForCode(code: string): string | null {
    return this.hardware().find(hardware => hardware.code === code)?.name
  }

  protected getValue(valueType: string, productOffering: ProductOffering): unknown | null {
    const characteristic = productOffering.prodSpecCharValueUse.find(char => char.valueType === valueType)

    return characteristic ? characteristic.productSpecCharacteristicValue[0].value : null
  }
}