import { GetterTree } from 'vuex'
import { RootState } from 'store'
import { Review } from '~/lib/review/ReviewProviderInterface'
import ReviewProvider from '~/lib/review/ReviewProvider'
import { Brand } from '~/lib/common/brand'

export const name = 'review'

export const namespaced = true

export interface State {}

let brand = (process.env.brand as any).code as Brand

if (brand === 'FUZ' || brand === 'HIS') {
  brand = 'UNI'
}

const reviewProvider = ReviewProvider.forBrand(brand)

export const getters: GetterTree<State, RootState> = {
  reviews (state): Review[] {
    return reviewProvider.getReviews()
  },
  businessReviews (state): Review[] {
    return reviewProvider.getReviews('business')
  }
}