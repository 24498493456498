import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { RootState } from 'store'

import { CreditCard } from '~/store/interfaces'

export const name = 'creditCard'

export const namespaced = true

export interface State {
  card: CreditCard,
}

export const state = (): State => ({
  card: {
    firstName: '',
    lastName: '',
    number: '',
    expiryMonth: '',
    expiryYear: '',
    cvv: '',
  },
})

export const getters: GetterTree<State, RootState> = {
  creditCard: state => state.card,
}

export interface Actions<S, R> extends ActionTree<S, R> {}
export const actions: Actions<State, RootState> = {}

export const mutations: MutationTree<State> = {
  setCardFirstName(state: State, firstName: string) {
    state.card.firstName = firstName
  },

  setCardLastName(state: State, lastName: string) {
    state.card.lastName = lastName
  },

  setCardNumber(state: State, number: string) {
    state.card.number = number
  },

  setCardExpiryMonth(state: State, expiry: string) {
    state.card.expiryMonth = expiry
  },

  setCardExpiryYear(state: State, expiry: string) {
    state.card.expiryYear = expiry
  },

  setCardExpiry(state: State, expiry: string) {
    if (expiry.length >= 2) {
      state.card.expiryMonth = expiry.substr(0, 2)
    }
    if (expiry.length >= 4 && expiry.length < 8) {
      state.card.expiryYear = expiry.substr(-2)
    }
  },

  setCardCvv(state: State, cvv: string) {
    state.card.cvv = cvv
  },
}
