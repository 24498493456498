import { ActionTree, GetterTree } from 'vuex'
import { RootState } from 'store'
import { Phone } from '~/store/interfaces'

export const name = 'phones'

export const namespaced = true

export interface State {
  phones: Phone[]
}

export const state = (): State => ({
  phones: [
    {
      id: 0,
      name: 'FIXED',
      price: 5,
      contractLength: 0,
      unTimed: 15,
      flagFall: 0,
      auPerMin: 23,
      auFlagFall: 0,
      onThreeCalls: 25,
      intPerMin: 4.9,
      intFlagFall: 19,
      tagLine: 'National Calls',
      localCalls: true,
      nationalCalls: true,
      oneEightCalls: true,
      mobileCalls: false,
      internationalCalls: false
    },
    {
      id: 1,
      name: 'MOBILE',
      price: 7,
      contractLength: 0,
      unTimed: 0,
      flagFall: 0,
      auPerMin: 21,
      auFlagFall: 0,
      onThreeCalls: 25,
      intPerMin: 4.9,
      intFlagFall: 19,
      tagLine: 'National Calls & Mobile Calls',
      localCalls: true,
      nationalCalls: true,
      oneEightCalls: true,
      mobileCalls: true,
      internationalCalls: false
    },
    {
      id: 2,
      name: 'GLOBAL',
      price: 9,
      contractLength: 0,
      unTimed: 0,
      flagFall: 0,
      auPerMin: 0,
      auFlagFall: 0,
      onThreeCalls: 25,
      intPerMin: 0,
      intFlagFall: 19,
      tagLine: 'National, Mobile & select International Calls',
      localCalls: true,
      nationalCalls: true,
      oneEightCalls: true,
      mobileCalls: true,
      internationalCalls: true
    }
  ]
})

export const getters: GetterTree<State, RootState> = {
  phones: state => state.phones
}
export interface Actions<S, R> extends ActionTree<S, R> {}
export const actions: Actions<State, RootState> = {}
