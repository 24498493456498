import ContactClientInterface, { ContactRequest, TokenRequest } from '~/lib/client/contact/ContactClientInterface'
import { HttpClientInterface } from '~/lib/client/HttpClientInterface'
import { application } from '~/store/api';

type RecaptchaResponse = {
  msg: string
}
export default class ContactClient implements ContactClientInterface {
  private client: HttpClientInterface

  constructor(client: HttpClientInterface) {
    this.client = client;
  }

  async create(contact: ContactRequest): Promise<void> {
    await this.client.post('/api/v1/contact', contact)
  }

  async validate(data: TokenRequest): Promise<any> {
    const response = await this.client.post<RecaptchaResponse>(`${application.apiUrl}/api/v1/recaptcha`, data)
    if (response && response.msg === 'validation failed') {
      throw new Error('Recaptcha failed')
    }
  }
}