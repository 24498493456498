
import { Vue, Component } from 'vue-property-decorator'

import RocketFail from '~/components/Icons/RocketFail.vue'

@Component({
  props: ['error'],
  head() {
    return {
      title: `Error - ${this.brand.name}`
    }
  },
  data() {
    return {
      brand: process.env.brand
    }
  },
  components: {
    RocketFail
  }
})
export default class Error extends Vue {}
