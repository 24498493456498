import JsonApi from 'devour-client'
import { ServiceQualificationClientFactory } from '@uniti-internet/sq-js-client'
import { v4 as uuidv4 } from 'uuid';
import AxiosHttpClient from '~/lib/client/AxiosHttpClient'
import ProductQualificationClient from '~/lib/client/pq/ProductQualificationClient'

const unitiSource = 'uniti'
const unitiConversationId = uuidv4()

const isUAT =
  process.env.baseUrl.indexOf('uat.unitiwireless.com') !== -1 ||
  process.env.baseUrl.indexOf('localhost:3000') !== -1 ||
  process.env.baseUrl.indexOf('dev.unitiinternet.com') !== -1

const sqV2 = {
  autocomplete: async address => {
    const client = sqV2.createClient()
    const addresses = await client.autocomplete(address)
    return addresses
  },

  sqCarrier: async (carrier, carrierId) => {
    const client = sqV2.createClient()
    const serviceQualifications = await client.sqCarrier(carrier, carrierId)
    return serviceQualifications
  },

  sqAddress: async address => {
    if (!address || !address.identifiers) {
      return []
    }

    const client = sqV2.createClient()
    const serviceQualifications = await client.sqAddress(address)

    // This has been added to help with SQ debugging.
    console.log(serviceQualifications)

    return serviceQualifications
  },

  sqText: async address => {
    const client = sqV2.createClient()
    const serviceQualifications = await client.sqText(address)
    return serviceQualifications
  },

  getSq: async id => {
    const client = sqV2.createClient()

    const sq = await client.getSq(id)

    // This has been added to help with SQ debugging.
    console.log(sq)

    return sq
  },

  createClient: () => {
    const apiUrl = isUAT
      ? 'https://sq.unitiinternet.com'
      : 'https://sq.unitiinternet.com'
    return ServiceQualificationClientFactory.create(apiUrl)
  }
}

const pqApiUrl = isUAT
  ? 'https://pq.dev.unitiinternet.com'
  : 'https://pq.unitiinternet.com'

const pqHttpClient = new AxiosHttpClient(pqApiUrl)
const pqClient = new ProductQualificationClient(pqHttpClient, unitiConversationId)

const pq = {
  getCharacteristicsForExternalId: async (externalId) => pqClient.getCharacteristicsForExternalId(externalId)
}

const sq = new JsonApi({
  apiUrl: isUAT
    ? 'https://service-qualification.unitiwireless.com/api/v1'
    : 'https://service-qualification.unitiwireless.com/api/v1'
})

const catalog = new JsonApi({
  apiUrl: isUAT
    ? 'https://catalog.dev.unitiinternet.com/api/v1'
    : 'https://catalog.unitiinternet.com/api/v1'
})
catalog.headers['Uniti-Source'] = unitiSource
catalog.headers['Uniti-ConversationId'] = unitiConversationId

const application = new JsonApi({
  apiUrl: isUAT
    ? 'https://application.dev.unitiinternet.com'
    : 'https://application.unitiinternet.com'
})

const promotion = new JsonApi({
  apiUrl: isUAT
    ? 'https://promotion.dev.unitiinternet.com/api'
    : 'https://promotion.unitiinternet.com/api'
})

const outage = new JsonApi({
  apiUrl: isUAT
    ? 'https://outage.dev.unitiinternet.com/api'
    : 'https://outage.unitiinternet.com/api'
})

const api = new JsonApi({
  apiUrl: 'https://universe.unitiwireless.com/api/v2'
})

const mergeUrl = isUAT ? 'https://unitiinternet.com/internet/broadband/' : 'https://unitiinternet.com/internet/broadband/'

application.define('carts', {
  id: '',
  relatedParty: {
    jsonApi: 'hasMany',
    type: 'CartDetails'
  },
  contactMedium: {
    jsonApi: 'hasMany',
    type: 'CustomerDetails'
  },
  cartTotalPrice: {
    jsonApi: 'hasMany',
    type: 'CartPrice'
  },
  cartItem: {
    jsonApi: 'hasMany',
    type: 'CartProduct'
  }
})

application.define('CartProduct', {
  itemPrice: [
    {
      taxIncludedAmount: {
        amount: null
      }
    }
  ],
  product: {
    name: '',
    productSerialNumber: '',
    productTerm: [
      {
        duration: {
          quantity: null
        }
      }
    ],
    characteristic: {
      jsonApi: 'hasMany',
      type: 'CartProductDetails'
    }
  }
})

application.define('CartProductDetails', {
  name: '',
  value: ''
})

application.define('CartDetails', {
  name: '',
  role: ''
})

application.define('CartPrice', {
  priceType: 'total',
  price: {
    taxIncludedAmount: {
      amount: null
    }
  }
})

application.define('CustomerDetails', {
  mediumType: '',
  mediumCharacteristic: {
    id: '',
    contactType: '',
    city: '',
    country: 'Australia',
    emailAddress: '',
    phoneNumber: '',
    postcode: '',
    socialNetworkId: '',
    stateOrProvince: '',
    street1: '',
    street2: ''
  }
})

application.define('CartCreditCard', {
  firstName: '',
  lastName: '',
  number: '',
  expiryMonth: '',
  expiryYear: '',
  cvv: '',
  token: '',
  provider: ''
})

api.define('application', {
  id: '',
  acceptedAt: '',
  activationFee: '',
  activationDate: null,
  firstName: '',
  lastName: '',
  ndc: '',
  locationId: '',
  email: '',
  height: '',
  manualBilling: '',
  phone: '',
  promo: '',
  referral: '',
  source: '',
  technology: '',
  uuid: '',
  creditCard: '',
  contract: '',
  download: '',
  upload: '',
  quota: '',
  sla: '',
  staticIp: '',
  status: '',
  notes: '',
  salesId: '',
  ticketId: '',
  businessName: '',
  shippingAddress: '',
  abn: '',
  relocationStatus: false,
  relocationOption: null,
  relocationSymbillID: null,
  relocationOldAddress: null,
  voiceListed: null,
  salesPerson: {
    jsonApi: 'hasOne',
    type: 'authenticate'
  },
  address: {
    jsonApi: 'hasOne',
    type: 'address'
  },
  building: {
    jsonApi: 'hasOne',
    type: 'building'
  },
  brand: {
    jsonApi: 'hasOne',
    type: 'brand'
  },
  network: {
    jsonApi: 'hasOne',
    type: 'network'
  },
  products: {
    jsonApi: 'hasMany',
    type: 'product'
  },
  addOns: {
    jsonApi: 'hasMany',
    type: 'addOn'
  },
  plan: {
    jsonApi: 'hasOne',
    type: 'plan'
  }
})

api.define('brand', {
  id: '',
  name: ''
})

api.define('product', {
  id: '',
  name: ''
})

api.define('addOn', {
  sku: ''
})

api.define('address', {
  address1: '',
  address2: '',
  unit: '',
  number: '',
  street: '',
  suburb: '',
  state: '',
  country: 'Australia',
  postcode: '',
  gnaf: '',
  lat: null,
  lng: null,
  formatted: ''
})

api.define('network', {
  id: '',
  name: '',
  identifier: '',
  carrierCode: '',
  priority: '',
  suites: {
    jsonApi: 'hasMany',
    type: 'suite'
  }
})

api.define('suite', {
  id: '',
  name: '',
  type: '',
  technology: '',
  startDate: '',
  endDate: '',
  active: '',
  cisUrl: '',
  networks: {
    jsonApi: 'hasMany',
    type: 'network'
  },
  plans: {
    jsonApi: 'hasMany',
    type: 'plan'
  }
})

api.define('plan', {
  id: '',
  displayName: '',
  name: '',
  price: '',
  activationFee: '',
  businessFee: '',
  contractLength: '',
  download: '',
  upload: '',
  quota: '',
  type: '',
  technology: '',
  suite: {
    jsonApi: 'hasOne',
    type: 'suite'
  }
})

api.define('service_qualification', {
  formattedName: '',
  technology: '',
  isAvailable: false,
  canProvision: false,
  notes: '',
  portSpeed: 100,
  ndc: 0,
  locationId: '',
  estate: '',
  height: null,
  address: {
    jsonApi: 'hasOne',
    type: 'address'
  },
  network: {
    jsonApi: 'hasOne',
    type: 'network'
  }
})

api.define('authenticate', {
  id: null,
  name: null,
  email: null
})

api.define('promo', {
  id: 0,
  code: '',
  description: '',
  type: '',
  planType: '',
  amountType: '',
  amount: null,
  percentage: null,
  productId: null,
  isValid: null,
  minDownload: null,
  minUpload: null,
  minContract: null,
  discounts: {
    jsonApi: 'hasMany',
    type: 'discount'
  }
})

api.define('discount', {
  id: 0,
  type: '',
  amountType: '',
  amount: null,
  product: {
    jsonApi: 'hasMany',
    type: 'product'
  }
})

export { unitiSource, unitiConversationId }
export { catalog }
export { api }
export { sqV2 }
export { sq }
export { pq }
export { application }
export { promotion }
export { outage }
export { mergeUrl }