import FaqProviderInterface, { Faq } from '~/lib/faq/FaqProviderInterface'

import accountFaq from '~/static/faq/account.json'
import apartmentsFaq from '~/static/faq/apartments.json'
import benergyFaq from '~/static/faq/benergy.json'
import broadbandFaq from '~/static/faq/broadband.json'
import clublinksFaq from '~/static/faq/clublinks.json'
import fixedWirelessBusinessFaq from '~/static/faq/fixed-wireless-business.json'
import fixedWirelessResidentialFaq from '~/static/faq/fixed-wireless-residential.json'
import homeFaq from '~/static/faq/home.json'
import hparkFaq from '~/static/faq/hpark.json'
import hxbbFaq from '~/static/faq/hxbb.json'
import nbnFaq from '~/static/faq/nbn.json'
import nbnAllFaq from '~/static/faq/nbn-all.json'
import phoneFaq from '~/static/faq/phone.json'
import ultraFaq from '~/static/faq/ultra.json'
import fuzenetFaq from '~/static/faq/fuzenet-migration.json'
import hispFaq from '~/static/faq/hisp-migration.json'
import velocityFaq from '~/static/faq/velocity.json'
import nbnMigrationFaq from '~/static/faq/nbn-migration.json'
import staticIP from '~/static/faq/static-ip.json'

export default class BaseFaqProvider implements FaqProviderInterface {
  private readonly brand: string

  private readonly phone: string

  private readonly supportEmail: string

  private readonly salesEmail: string

  public constructor(brand: string, phone: string, supportEmail: string, salesEmail: string) {
    this.brand = brand
    this.phone = phone
    this.supportEmail = supportEmail
    this.salesEmail = salesEmail
  }

  public getFaqs(): Array<Faq> {
    return this.compile(accountFaq.concat(
      apartmentsFaq,
      benergyFaq,
      broadbandFaq,
      clublinksFaq,
      fixedWirelessResidentialFaq,
      fixedWirelessBusinessFaq,
      homeFaq,
      hparkFaq,
      hxbbFaq,
      nbnFaq,
      nbnAllFaq,
      phoneFaq,
      ultraFaq,
      fuzenetFaq,
      hispFaq,
      velocityFaq,
      nbnMigrationFaq,
      staticIP
    ))
  }

  private compile(faqs: Array<Faq>): Array<Faq> {
    return faqs.map(faq => {
      const body = faq.body
        // eslint-disable-next-line no-template-curly-in-string
        .replace('${brand}', this.brand)
        // eslint-disable-next-line no-template-curly-in-string
        .replace('${phone}', this.phone)
        // eslint-disable-next-line no-template-curly-in-string
        .replace('${supportEmail}', this.supportEmail)
        // eslint-disable-next-line no-template-curly-in-string
        .replace('${salesEmail}', this.salesEmail)

      const name = faq.name
        // eslint-disable-next-line no-template-curly-in-string
        .replace('${brand}', this.brand)
        // eslint-disable-next-line no-template-curly-in-string
        .replace('${phone}', this.phone)
        // eslint-disable-next-line no-template-curly-in-string
        .replace('${supportEmail}', this.supportEmail)
        // eslint-disable-next-line no-template-curly-in-string
        .replace('${salesEmail}', this.salesEmail)

      return {
        id: faq.id,
        type: faq.type,
        body,
        name,
      }
    })
  }

  getFaqsByType(type: string): Array<Faq> {
    return this.compile(this.getFaqs().filter(faq => faq.type === type))
  }
}