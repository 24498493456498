
import { namespace } from 'vuex-class'
import { Vue, Component } from 'vue-property-decorator'
import Twitter from '~/components/Icons/Twitter.vue'
import Wechat from '~/components/Icons/Wechat.vue'
import Facebook from '~/components/Icons/Facebook.vue'
import Instagram from '~/components/Icons/Instagram.vue'
import Youtube from '~/components/Icons/Youtube.vue'
import Linkedin from '~/components/Icons/Linkedin.vue'
import Phone from '~/components/Icons/Phone.vue'
import Mail from '~/components/Icons/Mail.vue'
import Location from '~/components/Icons/LocationMarker.vue'
import LogoNew from '~/components/Brand/LogoNew.vue'
import Logo from '~/components/Brand/Logo.vue'
import Modal from '~/components/layouts/Modal.vue'
import * as sq from '~/store/modules/sq'

const SQ = namespace(sq.name)

@Component({
  name: 'PageFooter',
  components: {
    Instagram,
    Facebook,
    Mail,
    Phone,
    Twitter,
    Logo,
    LogoNew,
    Youtube,
    Linkedin,
    Location,
    Wechat,
    Modal
  },
  data() {
    return {
      year: new Date().getFullYear(),
      siteId: process.env.siteId,
      brand: process.env.brand
    }
  }

})
export default class Home extends Vue {
  @SQ.State
  sq

  wechatModal = false

  toggleWechat() {
    this.wechatModal = true
  }
  
  closeModal() {
    this.wechatModal = false
  }

  currentRoute() {
    return this.$router.currentRoute.name
  }
}
