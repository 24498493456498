import { RootState } from 'store'
import { ActionTree } from 'vuex'
import AxiosHttpClient from '~/lib/client/AxiosHttpClient'
import ContactClient from '~/lib/client/contact/ContactClient'
import ContactRequestBuilder from '~/lib/contact/ContactRequestBuilder'
import { ContactForm } from '~/lib/contact/ContactForms'

export const name = 'contact'

export interface State {}

export const namespaced = true

export interface Actions<S, R> extends ActionTree<S, R> {}

const httpClient = new AxiosHttpClient(process.env.applicationUrl)
const contactClient = new ContactClient(httpClient)
const contactRequestBuilder = new ContactRequestBuilder((process.env.brand as any).code)

export const actions: Actions<State, RootState> = {
  async create({ state, commit}, form: ContactForm) {
    await contactClient.create(contactRequestBuilder.create(form))
  },

  async validate({ state, commit}, data: any) {
    await contactClient.validate(data)
  }
} 
