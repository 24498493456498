/* eslint-disable dot-notation */
import { RootState } from 'store'
import {
  ActionTree,
  MutationTree,
  GetterTree,
  createNamespacedHelpers
} from 'vuex'

import { api, catalog, sq, sqV2 } from '~/store/api'

import {
  Address,
  Network,
  ServiceQualification,
  Location,
  Carrier
} from '~/store/interfaces'

export const name = 'sq'
export interface State {
  sq: ServiceQualification
  addressAutocompleteInProgress: Boolean
  addressAutocomplete: Address[] | null[]
  addressResults: Object[] | null[]
  addressChecked: Boolean
  cannotFindAddress: Boolean
  availableHeights: Number[]
  advancedSearch: Boolean
  advancedAddressString: String
  suggestedPromo: Object | null
  qualification: Object | null
  partner: String | null
  addressAutocompleteSearch: String | null
  existingServiceQualification: string | null
  sqId: string | null
  isBtr: boolean
  isBusiness: boolean
}

export const namespaced = true

export const state = (): State => ({
  sq: {
    address: {
      id: null,
      address1: '',
      address2: '',
      carrier: null,
      unit: '',
      number: '',
      street: '',
      suburb: '',
      state: '',
      country: 'Australia',
      postcode: '',
      gnaf: null,
      lat: null,
      lng: null,
      links: {},
      address: '',
      formatted: '',
      siteName: '',
      developmentName: '',
      serviceClass: ''
    },
    networks: [],
    defaultNetworks: [
      {
        id: 0,
        name: 'Home Wireless Broadband 4G',
        identifier: 'hwbb',
        carrierCode: 'HW',
        priority: 12,
        maxDownload: 0,
        maxUpload: 0,
        suites: []
      },
      {
        id: 1,
        name: 'Home Wireless Broadband 5G',
        identifier: 'hwbb',
        carrierCode: 'HX',
        priority: 11,
        maxDownload: 0,
        maxUpload: 0,
        suites: []
      },
      {
        id: 2,
        name: 'Fixed Wireless',
        identifier: 'wireless',
        carrierCode: 'UW',
        priority: 6,
        maxDownload: 100,
        maxUpload: 40,
        suites: []
      },
      {
        id: 3,
        name: 'Fixed Wireless',
        identifier: 'wireless',
        carrierCode: 'WIR',
        priority: 5,
        maxDownload: 100,
        maxUpload: 40,
        suites: []
      },
      {
        id: 4,
        name: 'Opticomm',
        identifier: 'opticomm',
        carrierCode: 'OC',
        priority: 1,
        maxDownload: 1000,
        maxUpload: 50,
        suites: []
      },
      {
        id: 5,
        name: 'LBNCo',
        identifier: 'lbnco',
        carrierCode: 'LB',
        priority: 2,
        maxDownload: 1000,
        maxUpload: 50,
        suites: []
      },
      {
        id: 6,
        name: 'OPENetworks',
        identifier: 'opennetworks',
        carrierCode: 'ON',
        priority: 3,
        maxDownload: 1000,
        maxUpload: 50,
        suites: []
      },
      {
        id: 7,
        name: 'Fixed Line',
        identifier: 'fixedline',
        carrierCode: 'UFL',
        priority: 4,
        maxDownload: 250,
        maxUpload: 40,
        suites: []
      },
      {
        id: 8,
        name: 'Fixed Line',
        identifier: 'fixedline',
        carrierCode: 'FL',
        priority: 14,
        maxDownload: 250,
        maxUpload: 40,
        suites: []
      },
      {
        id: 9,
        name: 'NBN',
        identifier: 'nbn',
        carrierCode: 'OF',
        priority: 9,
        maxDownload: 1000,
        maxUpload: 50,
        suites: []
      },
      {
        id: 10,
        name: 'NBN',
        identifier: 'nbn',
        carrierCode: 'NB',
        priority: 13,
        maxDownload: 1000,
        maxUpload: 50,
        suites: []
      },
      {
        id: 11,
        name: 'Sky Muster',
        identifier: 'skymuster',
        carrierCode: 'SM',
        priority: 7,
        maxDownload: 25,
        maxUpload: 5,
        suites: []
      },
      {
        id: 12,
        name: 'Sky Muster Plus',
        identifier: 'skymusterplus',
        carrierCode: 'SMP',
        priority: 8,
        maxDownload: 25,
        maxUpload: 5,
        suites: []
      },
      {
        id: 13,
        name: 'Red Train',
        identifier: 'redtrain',
        carrierCode: 'RT',
        priority: 10,
        maxDownload: 100,
        maxUpload: 40,
        suites: []
      },
      {
        id: 14,
        name: 'Fibre400',
        identifier: 'fibre400',
        carrierCode: 'FB4',
        priority: 15,
        maxDownload: 400,
        maxUpload: 400,
        suites: []
      },
      {
        id: 15,
        name: 'Fibre1000',
        identifier: 'fibre1000',
        carrierCode: 'FB10',
        priority: 16,
        maxDownload: 1000,
        maxUpload: 1000,
        suites: []
      }
    ],
    locations: [
      {
        networkIdentifier: '',
        locationId: '',
        ndc: 0,
        technology: '',
        isAvailable: false,
        canProvision: false,
        rtcDate: null,
        isPreOrder: false
      }
    ],
    id: '',
    isAvailable: false,
    canProvision: false,
    isPreOrder: false,
    links: {},
    technology: '',
    type: '',
    notes: '',
    locationId: '',
    estate: '',
    ndc: 0,
    ncc: 0,
    identifier: ''
  },
  addressAutocomplete: [],
  addressResults: [],
  addressAutocompleteInProgress: false,
  addressAutocompleteSearch: '',
  addressChecked: false,
  cannotFindAddress: false,
  availableHeights: [2.4],
  advancedSearch: false,
  advancedAddressString: '',
  suggestedPromo: null,
  qualification: [],
  partner: null,
  existingServiceQualification: null,
  sqId: null,
  isBtr: false,
  isBusiness: false
})

export const getters: GetterTree<State, RootState> = {
  sq: state => state.sq,

  partner: state => state.partner,

  networks: state => state.sq.networks,

  networkByCarrier: state => (carrier: Carrier): Network | undefined => {
    if (state.sq.networks.length > 0) {
      return (state.sq.networks as Network[]).find(n => n.carrierCode === carrier)
    }

    return undefined
  },

  hasFttpUpgrade: (state, getters): boolean =>
    getters.primaryNetwork.alternateTechnology === 'FTTP' &&
    getters.primaryNetwork.alternateTechnologyStatus === 'OnDemandReady',

  hasFttpUpgradeComing: (state, getters): boolean =>
    getters.primaryNetwork.alternateTechnology === 'FTTP' &&
    getters.primaryNetwork.alternateTechnologyStatus === 'OnDemandPlanned',

  defaultNetworks: state => state.sq.defaultNetworks,

  primaryNetwork: state =>
    state.sq.networks.length > 0
      ? state.sq.networks[0]
      : state.sq.defaultNetworks[0],

  isAvailable: state => state.sq.isAvailable,

  canProvision: state => state.sq.canProvision,

  address: state => state.sq.address,

  addressAutocompleteInProgress: state => state.addressAutocompleteInProgress,

  addressAutocomplete: state => state.addressAutocomplete,

  addressAutocompleteSearch: state => state.addressAutocompleteSearch,

  addressChecked: state => state.addressChecked,

  addressResults: state => state.addressResults,

  availableHeights: state => state.availableHeights,

  locationId: state => state.sq.locationId,

  locations: state => state.sq.locations,

  ndc: state => {
    if (state.sq.networks.length) {
      return state.sq.networks[0].ndc
    }
    return 0
  },
  
  ncc: state => {
    if (state.sq.networks.length) {
      return state.sq.networks[0].ncc
    }
    return 0
  },

  technology: state => state.sq.technology,

  suggestedPromo: state => state.suggestedPromo,

  addressString: state => {
    let unit = 'no_unit+'
    if (state.sq.address.unit.length > 0) {
      unit = `${state.sq.address.unit}+`
    }
    return `${unit}${state.sq.address.number}+${state.sq.address.street}+${
      state.sq.address.suburb
    }+${state.sq.address.state}+${state.sq.address.postcode}`
  },

  sqId: state => state.sqId,
  isBtr: state => state.isBtr,
  isBusiness: state => state.isBusiness,
  isVelocity: state => state.sq ? state.sq.locationId.startsWith('OPC3') : false
}

export const actions: ActionTree<State, RootState> = {
  async fetchAddressSuggestions(
    { state, commit, getters, rootGetters },
    carrier: Carrier | null = null
  ) {
    const addressAutocompleteSearch =
      rootGetters['sq/addressAutocompleteSearch']

    try {
      let sqString = addressAutocompleteSearch
        ? rootGetters['sq/addressAutocompleteSearch']
        : state.sq.address.address

      if (carrier !== null) {
        sqString += `&carrier=${carrier}`
      }

      commit('updateAddressAutocompleteInProgress', true)
      commit('updateAutocompleteSuggestions', [])
      const addresses = await sqV2.autocomplete(sqString)
      commit('updateAutocompleteSuggestions', addresses)
      commit('updateAddressAutocompleteInProgress', false)
    } catch (e) {
      commit('updateAddressAutocompleteInProgress', false)
      console.log('Error: Problem getting autocomplete address from sq: ', e)
    }
  },

  async updateUTMData({ state, commit }, utmData: object) {
    commit('cart/setUTMData', utmData, { root: true })
  },

  async serviceQualification({ state, commit, getters, rootGetters, dispatch }) {
    // Go get Product Qualification information as well, but we don't have to care about it completing here
    if((state.sq.address as any).carrier_id) {
      await dispatch('pq/getCharacteristicsForExternalId', (state.sq.address as any).carrier_id, { root: true })
    }

    /**
     * SERVICE QUALIFICATION
     */
    let serviceQualification = []

    try {
      if (state.advancedSearch) {
        serviceQualification = await sqV2.sqText(state.sq.address.address)
      } else if (state.existingServiceQualification) {
        const sqResult = await sqV2.getSq(state.existingServiceQualification)
        serviceQualification.push(sqResult.length === 0 ? [] : sqResult[0])
      } else if ((state.sq.locationId && state.sq.address.carrier && !state.existingServiceQualification) || rootGetters['cart/voipOnly']) {
        const sqResult = await sqV2.sqCarrier(state.sq.address.carrier, state.sq.locationId)
        serviceQualification.push(sqResult)
      }
      else {
        serviceQualification = await sqV2.sqAddress(state.sq.address)
      }
    } catch (e) {
      console.log('Error getting Service Qualification: ', e)
    }

    if (serviceQualification.length && serviceQualification[0].sq_id) {
      commit('setSqId', serviceQualification[0].sq_id)
      commit('setIsBtr', serviceQualification[0].is_btr ? serviceQualification[0].is_btr : false)
    }
    

    if (
      serviceQualification.some(
        q => q.carrier === 'OC' || q.carrier === 'LB' || q.carrier === 'ON'
      )
    ) {
      serviceQualification = serviceQualification.filter(
        q => q.carrier === 'OC' || q.carrier === 'LB' || q.carrier === 'ON'
      )
    }

    serviceQualification.filter(s => {
      if (s.carrier === 'NB' || s.carrier === 'OF') {
        switch (s.technology) {
          case 'WIR':
            // eslint-disable-next-line no-param-reassign
            s.carrier = 'WIR'
            break
          case 'SAT':
            // eslint-disable-next-line no-param-reassign
            s.carrier = 'SM'
            break
          case 'SMP':
            // eslint-disable-next-line no-param-reassign
            s.carrier = 'SMP'
            return false
          default:
            break
        }
      }
      return serviceQualification
    })

    let filteredServiceQualification = serviceQualification
      .filter(s => process.env.carrierCodes.includes(s.carrier))
      .sort((a: any, b: any) => b.max_download - a.max_download)

    if (filteredServiceQualification.find(a => a.carrier === 'SM')) {
      const skyMusterService = filteredServiceQualification
        .filter(a => a.carrier === 'SM')
        .find(e => !!e)

      const newSQ = {
        address: skyMusterService.address,
        building: skyMusterService.building,
        carrier: 'SMP',
        carrier_id: skyMusterService.carrier_id,
        development_name: skyMusterService.development_name,
        gnaf: skyMusterService.gnaf,
        id: skyMusterService.id,
        level_number: skyMusterService.level_number,
        level_type: skyMusterService.level_type,
        lot_number: skyMusterService.lot_number,
        max_download: skyMusterService.max_download,
        max_upload: skyMusterService.max_upload,
        min_download: skyMusterService.min_download,
        min_upload: skyMusterService.min_upload,
        ndc: skyMusterService.ndc,
        postcode: skyMusterService.postcode,
        rtc_date: skyMusterService.rtc_date,
        service_class: skyMusterService.service_class,
        state: skyMusterService.state,
        street_name: skyMusterService.street_name,
        street_number: skyMusterService.street_number,
        street_suffix: skyMusterService.street_suffix,
        street_type: skyMusterService.street_type,
        suburb: skyMusterService.suburb,
        technology: 'SMP',
        topology: 'SMP',
        unit_number: skyMusterService.unit_number,
        unit_type: skyMusterService.unit_type,
        alternate_technology: skyMusterService.alternate_technology,
        alternate_technology_status:
          skyMusterService.alternate_technology_status,
        alternate_technology_date: skyMusterService.alternate_technology_date,
        ntd_location: skyMusterService.ntd_location
      }

      filteredServiceQualification.push(newSQ)
    }

    filteredServiceQualification = filteredServiceQualification.map((s, i) => {
      let currentNetworks = getters['defaultNetworks']

      currentNetworks = currentNetworks.filter(n => n.carrierCode === s.carrier)

      const rtcDate = s.rtc_date ? new Date(s.rtc_date) : null
      const isPreOrder =
        rtcDate && s.carrier === 'OC' ? rtcDate > new Date() : false
      const alternateTechnologyDate = s.alternate_technology_date
        ? new Date(s.alternate_technology_date)
        : null

      const network = {
        rtcDate,
        isPreOrder,
        alternateTechnologyDate,
        id: currentNetworks[0].id,
        name: currentNetworks[0].name,
        identifier: currentNetworks[0].identifier,
        carrierCode: s.carrier,
        carrierId: s.carrier_id,
        priority: currentNetworks[0].priority,
        maxDownload: s.max_download,
        maxUpload: s.max_upload,
        minDownload: s.min_download,
        minUpload: s.min_upload,
        ndc: s.ndc,
        ncc: s.ncc,
        technology: s.technology,
        sq: s.id,
        alternateTechnology: s.alternate_technology,
        alternateTechnologyStatus: s.alternate_technology_status
      }

      return { ...s, network }
    })

    if (filteredServiceQualification.length <= 0) {
      commit('notServiceable')
      return
    }

    /**
     * APPLICATION/CART
     */
    filteredServiceQualification.sort(
      (a: any, b: any) => a.network.priority - b.network.priority
    )

    commit('setServiceQualification', filteredServiceQualification)
    try {
      commit(
        'cart/setDealer',
        ['FL', 'SM', 'SMP', 'WIR'].includes(
          filteredServiceQualification[0].network.carrierCode
        )
          ? 'NB'
          : filteredServiceQualification[0].network.carrierCode,
        {
          root: true
        }
      )
      commit(
        'cart/setLocation',
        filteredServiceQualification[0].network.carrierId,
        {
          root: true
        }
      )
      commit(
        'cart/setTechnology',
        rootGetters['cart/voipOnly']
          ? 'VOIP'
          : filteredServiceQualification[0].network.technology,
        {
          root: true
        }
      )
      commit('cart/setAddress', filteredServiceQualification[0], {
        root: true
      })
      commit('cart/setSource', 'Website', { root: true })
      commit('cart/setBrand', (process.env.brand as any).code, { root: true })
    } catch (err) {
      console.log('Error setting values to application: ', err)
    }    

    commit('setAvailableHeightsAvailable', [2.4])

    commit('setAddressChecked')
  }
}

export const mutations: MutationTree<State> = {
  setAvailableHeightsAvailable(state: State, b: []) {
    state.availableHeights = b
  },

  setAdvancedSearch(state: State, s: Boolean) {
    state.advancedSearch = s
  },

  setAdvancedAddressString(state: State, a: string) {
    state.advancedAddressString = a
  },

  setAddress(state: State, a: Address) {
    state.sq.address = a
  },

  setAddressString(state: State, a: string) {
    state.sq.address.address = a
  },

  clearAddressString(state: State) {
    state.sq.address = {
      id: null,
      address1: '',
      address2: '',
      carrier: null,
      unit: '',
      number: '',
      street: '',
      suburb: '',
      state: '',
      country: 'Australia',
      postcode: '',
      gnaf: null,
      lat: null,
      lng: null,
      address: '',
      formatted: '',
      links: {},
      siteName: '',
      developmentName: '',
      serviceClass: null
    }
    state.sq.id = ''
    state.sq.isAvailable = true
    state.sq.canProvision = true
    state.sq.isPreOrder = false
    state.sq.links = {}

    state.sq.technology = ''
    state.sq.type = ''
    state.sq.notes = ''
    state.addressChecked = false
    state.availableHeights = []
    state.advancedSearch = false
    state.sq.locations = []
    state.sq.locationId = ''
    state.sq.ndc = 0
  },

  updateAddressAutocompleteInProgress(
    state: State,
    autocompleteInProgress: Boolean
  ) {
    state.addressAutocompleteInProgress = autocompleteInProgress
  },

  updateAutocompleteSuggestions(state: State, addresses: Address[]) {
    if (addresses) {
      state.addressAutocomplete = addresses
      state.cannotFindAddress = false
    } else {
      state.cannotFindAddress = true
    }
    state.addressChecked = false
    state.availableHeights = []
    state.advancedSearch = false
    state.sq.isAvailable = false
    state.sq.canProvision = false
    state.sq.isPreOrder = false
    state.sq.id = ''
  },

  setAddressAutocompleteSearch(state: State, searchText: String) {
    state.addressAutocompleteSearch = searchText
  },

  setServiceQualification(state: State, result: any[]) {
    const results = result

    const tempResults = []
    result.forEach(async add => {
      const tempAdd = {
        id: null,
        address1: '',
        address2: '',
        carrier: null,
        unit: '',
        number: '',
        street: '',
        suburb: '',
        state: '',
        country: 'Australia',
        postcode: '',
        gnaf: null,
        lat: null,
        lng: null,
        address: '',
        formatted: '',
        links: {},
        siteName: '',
        developmentName: '',
        serviceClass: null
      }
      tempAdd.address = add.address
      tempAdd.formatted = add.address
      tempAdd.carrier = add.carrier
      tempAdd.unit = `${add.unit_type} ${add.unit_number}`
      tempAdd.address1 = `${add.street_number} ${add.street_name} ${
        add.street_type
      }`
      tempAdd.suburb = add.suburb
      tempAdd.state = add.state
      tempAdd.postcode = add.postcode
      tempAdd.serviceClass =
        add.service_class === null
          ? add.service_class
          : add.service_class.toString()
      tempAdd.id = add.id
      tempAdd.siteName = add.site_name
      tempAdd.developmentName = add.development_name
        ? add.development_name.normalize('NFC').toUpperCase()
        : add.development_name

      tempResults.push(tempAdd)
    })

    state.addressResults = tempResults

    if (results[0].id) {
      this.$gtm.pushEvent({
        event: 'ADDRESS_SERVICEABLLE',
        type: results[0].topology,
        location_id: results[0].carrier_id
      })

      switch (results[0].network.carrierCode) {
        case 'UW':
          this.$gtm.pushEvent({ event: 'WIRELESS_PROVISIONABLE' })
          break
        case 'OF':
          this.$gtm.pushEvent({ event: 'NBN_PROVISIONABLE' })
          break
        case 'NB':
          this.$gtm.pushEvent({ event: 'NBN_PROVISIONABLE' })
          break
        case 'SM':
          this.$gtm.pushEvent({ event: 'NBN_SKYMUSTER_PROVISIONABLE' })
          break
        case 'SMP':
          this.$gtm.pushEvent({ event: 'NBN_SKYMUSTERPLUS_PROVISIONABLE' })
          break
        case 'OC':
          this.$gtm.pushEvent({ event: 'OPTICOMM_PROVISIONABLE' })
          break
        case 'LB':
          this.$gtm.pushEvent({ event: 'LBNCO_PROVISIONABLE' })
          break
        case 'ON':
          this.$gtm.pushEvent({ event: 'OPENETWORKS_PROVISIONABLE' })
          break
        default:
          this.$gtm.pushEvent({ event: 'BROADBAND_PROVISIONABLE' })
          break
      }

      const conditions = ['UW', 'OF', 'NBN']
      if (!conditions.some(el => results[0].network.carrierCode.includes(el))) {
        this.$gtm.pushEvent({ event: 'BROADBAND_PROVISIONABLE' })
      }
    } else {
      this.$gtm.pushEvent({
        event: 'NOT_PROVISIONABLE',
        type: results[0].topology
      })
    }

    const rtcDate = results[0].rtc_date ? new Date(results[0].rtc_date) : null

    state.sq.address.address = results[0].address
    state.sq.address.formatted = results[0].address
    state.sq.address.unit = `${results[0].unit_type} ${results[0].unit_number}`
    state.sq.address.address1 = `${results[0].street_number} ${
      results[0].street_name
    } ${results[0].street_type}`
    state.sq.address.suburb = results[0].suburb
    state.sq.address.state = results[0].state
    state.sq.address.postcode = results[0].postcode
    state.sq.address.serviceClass =
      results[0].service_class === null
        ? results[0].service_class
        : results[0].service_class.toString()
    state.sq.id = results[0].id
    state.sq.isAvailable = true
    state.sq.canProvision = true
    state.sq.isPreOrder =
      rtcDate && results[0].carrier === 'OC' ? rtcDate > new Date() : false
    state.sq.type = results[0].type
    state.sq.locationId = results[0].carrier_id
    state.sq.notes = results[0].notes
    state.sq.ndc = results[0].ndc
    state.sq.ncc = results[0].ncc
    state.sq.identifier = results[0].topology
    state.sq.address.siteName = results[0].site_name
    state.sq.address.developmentName = results[0].development_name
      ? results[0].development_name.normalize('NFC').toUpperCase()
      : results[0].development_name

    let networks = []
    results.forEach(result => networks.push(result.network))

    const mergedNetworks = new Set()
    networks = networks
      .filter(
        n =>
          !mergedNetworks.has(n.carrierCode) &&
          mergedNetworks.add(n.carrierCode)
      )
      .sort((a: Network, b: Network) => a.priority - b.priority)

    state.sq.technology = networks[0].technology
    state.sq.networks = [...new Set(networks)]
  },

  setServiceQualificationAddressString(state: State, addressString: string) {
    state.sq.address.address = addressString
    state.sq.address.formatted = addressString
  },

  updatePrimaryNetwork(state: State, network: Network) {
    const currentNetworks = state.sq.networks.length
      ? state.sq.networks
      : state.sq.defaultNetworks

    currentNetworks.forEach((n: Network) => {
      if (n.carrierCode === network.carrierCode) {
        const loc = state.sq.locations.filter(
          (loc: Location) => loc.networkIdentifier === network.carrierCode
        )

        if (loc.length) {
          state.sq.locationId = loc[0].locationId
          state.sq.ndc = loc[0].ndc
          state.sq.technology = loc[0].technology
          state.sq.isAvailable = loc[0].isAvailable
          state.sq.canProvision = loc[0].canProvision
          state.sq.identifier = loc[0].technology
          state.sq.isPreOrder =
            loc[0] && loc[0].networkIdentifier === 'OC'
              ? loc[0].rtcDate > new Date()
              : false
        }

        n.priority = 0

        if (
          currentNetworks.find(fn => fn.carrierCode === network.carrierCode)
        ) {
          const cn = currentNetworks.findIndex(
            fn => fn.carrierCode === network.carrierCode
          )
          currentNetworks[cn] = n
        } else {
          currentNetworks.push(n)
        }
      } else {
        n.priority += 1 // otherwise we get multiple with a 0 priority
      }
    })

    currentNetworks.sort((a: Network, b: Network) => a.priority - b.priority)

    state.sq.networks = currentNetworks
  },

  setAddressChecked(state: State) {
    this.$gtm.pushEvent({
      event: 'ADDRESS_CHECK',
      partner: state.partner
    })
    state.addressChecked = true
  },

  setNetworks(state: State, networks: Network[]) {
    state.sq.networks = networks
  },

  setLocations(state: State, locations: []) {
    state.sq.locations = locations
  },

  notServiceable() {
    this.$gtm.pushEvent({ event: 'ADDRESS_NOT_SERVICEABLE' })
  },

  // setSuggestedPromo(state: State, promo: {}) {
  //   state.suggestedPromo = promo
  // },

  setQualification(state: State, qualification: Object) {
    state.qualification = qualification
  },

  setSQPartner(state: State, partner: String) {
    state.partner = partner
  },

  setLocationId(state: State, locId: string) {
    state.sq.locationId = locId
  },

  setCarrierCode(state: State, carrier: Carrier) {
    state.sq.address.carrier = carrier
  },

  setExistingServiceQualification(state: State, id: string) {
    state.existingServiceQualification = id
  },

  setSqId(state: State, id: string) {
    state.sqId = id
  },

  setIsBtr(state: State, isBtr: boolean) {
    state.isBtr = isBtr
  },

  setIsBusiness(state: State, isBiz: boolean) {
    state.isBusiness = isBiz
  }
}
