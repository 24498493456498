<template>
  <div>
    <logo-fuzenet v-if="siteId === 'fuz'" />
    <logo-premium v-else-if="siteId === 'pre'" />
    <logo-hisp v-else-if="siteId === 'hisp'" />
    <logo-quantum v-else-if="siteId === 'qnet'" />
    <logo-uniti v-else />
  </div>
</template>
<script>
import LogoFuzenet from '~/components/Brand/LogoFuzenet.vue'
import LogoPremium from '~/components/Brand/LogoPremium.vue'
import LogoUniti from '~/components/Brand/LogoUnitiNew.vue'
import LogoHisp from '~/components/Brand/LogoHisp.vue'
import LogoQuantum from '~/components/Brand/LogoQuantumNet.vue'

export default {
  components: {
    LogoFuzenet,
    LogoPremium,
    LogoUniti,
    LogoHisp,
    LogoQuantum
  },
  data() {
    return {
      siteId: process.env.siteId
    }
  }
}
</script>
<style scoped>
.a {
  fill: #01516e;
}
.b {
  fill: #51bb7b;
}
</style>
