import ProductQualificationClientInterface, {
  Characteristic, Characteristics, CharacteristicType
} from '~/lib/client/pq/ProductQualificationClientInterface'
import { HttpClientInterface } from '~/lib/client/HttpClientInterface'
import { v4 as uuidv4 } from 'uuid';

const HEADER_UNITI_SOURCE = 'uniti';

export type ApiCharacteristic = {
  id: string,
  name: string,
  type: CharacteristicType,
  value: string|number|boolean,
  // eslint-disable-next-line camelcase
  created_at: string,
  // eslint-disable-next-line camelcase
  updated_at: string,
}

export default class ProductQualificationClient implements ProductQualificationClientInterface {

  private client: HttpClientInterface

  private readonly unitiConversationId: string

  constructor(client: HttpClientInterface, unitiConversationId: string) {
    this.client = client;
    this.unitiConversationId = unitiConversationId;
  }

  async getCharacteristicsForExternalId(externalId: string): Promise<Characteristics> {
    // TODO: Handle errors
    const characteristics = await this.client
      .get<ApiCharacteristic[]>('/api/v1/characteristics', {
        params: {
          externalId
        },
        headers: {
          'Uniti-Source': HEADER_UNITI_SOURCE,
          'Uniti-ConversationId': this.unitiConversationId,
          'Uniti-TransactionId': uuidv4()
        }
      })

    // Convert the list of characteristics into a lookup table (Map) for easier use.
    return new Map<string, Characteristic>(characteristics
      .map<[string, Characteristic]>((characteristic: ApiCharacteristic): [string, Characteristic] => (
            [characteristic.name, {
              id: characteristic.id,
              name: characteristic.name,
              type: characteristic.type,
              value: characteristic.value,
              createdAt: new Date(characteristic.created_at),
              updatedAt: new Date(characteristic.updated_at)
            }])
      ))
  }
}