import CatalogWrapper from '~/lib/catalog/CatalogWrapper'
import { CartProduct, Catalog, Hardware, Network, Plan } from '~/store/interfaces'
import { Brand } from '~/lib/common/brand'
import { isWithinInterval } from 'date-fns'
import { Characteristics } from '~/lib/client/pq/ProductQualificationClientInterface'

export default class FilteredCatalogWrapper {
  private readonly catalogWrapper: CatalogWrapper

  private readonly brand: Brand

  constructor(catalogWrapper: CatalogWrapper, brand: Brand) {
    this.catalogWrapper = catalogWrapper
    this.brand = brand
  }

  wrapper(): CatalogWrapper {
    return this.catalogWrapper
  }

  internet(): Catalog[] {
    return this.catalogWrapper.internet()
      // Filter out Satellite plans on Harbour so that we can still SQ but plans will never be shown
      .filter(catalog => !(this.brand === 'HIS' && ['SM', 'SMP'].includes(catalog.name)))
      // Filter out all NBN so that it doesn't show in the NavBar dropdown
      .filter(catalog => !(catalog.name === 'NB'))
      .filter(catalog => isWithinInterval(new Date(), {
        start: new Date(catalog.validFor.startDateTime),
        end: new Date(catalog.validFor.endDateTime)
      }))
  }

  plans(primaryNetwork: Network, activePromo: any, group?: string, planGroup: string = null): Plan[] {
    let addNext = true

    return this.catalogWrapper.plans(group, planGroup)
      .filter(plan => plan.isSellable)
      .filter(plan => plan.carrierCode === primaryNetwork.carrierCode)
      // Filter out Satellite plans on Harbour so that we can still SQ but plans will never be shown
      .filter(plan => !(this.brand === 'HIS' && ['SM', 'SMP'].includes(plan.carrierCode)))
      .filter(plan => {
        if ((plan.download <= primaryNetwork.maxDownload ||
          (plan.download > primaryNetwork.maxDownload &&
            plan.download * 0.8 <= primaryNetwork.maxDownload && // planDownload > maxDownload > 80% of planDownload
            plan.carrierCode === 'NB' &&
            ['FTTN', 'FTTB'].includes(
              primaryNetwork.technology
            ) &&
            addNext))
        ) {
          if (
            plan.download > primaryNetwork.maxDownload &&
            plan.download * 0.8 <= primaryNetwork.maxDownload && // planDownload > maxDownload > 80% of planDownload
            plan.carrierCode === 'NB' &&
            ['FTTN', 'FTTB'].includes(
              primaryNetwork.technology
            )
          ) {
            addNext = false
          }

          if (!activePromo) {
            if (plan.description.includes('PROMO')) {
              return false
            }
          }

          return true
        }
        return false
      })
      .sort((a, b) => a.download - b.download)
  }

  hardware(primaryNetwork: Network, voiceInCart?: CartProduct[], group: string = 'Residential', isVelocity: boolean = false, characteristics?: Characteristics, plan?:CartProduct): Hardware[] {
    const hardware = this.catalogWrapper.hardware(group)

    if (group.toString().includes('Business')) {
      return hardware.filter(hw => hw.catalogGroup.includes('Business'))
    }

    // Only show the 4G router if the technology selected is HWBB 4G
    if (primaryNetwork.carrierCode === 'HW') {
      return hardware.filter(hw => hw.name.includes('NL1901ACV'))
    }

    // Only show the 5G router if the technology selected is HWBB 4G
    if (primaryNetwork.carrierCode === 'HX') {
      return hardware.filter(hw => hw.name.includes('Nokia FastMile'))
    }
    
    // We want to offer NF18ACVs in Lifestyle Villages due to the elderly demographic
    if (characteristics !== undefined && characteristics.has('is_lifestyle_village') && characteristics.get('is_lifestyle_village').value === true) {
      return hardware.filter(hw => hw.name.includes('NF18ACV'))
    }

    // if addon voip selected, show Netcomm despite tech conditions
    if (voiceInCart.length && voiceInCart[0].id) {
      // If this is a Velocity site we only want to show the NF18ACV's as these have a voice
      // port by default and are cheaper than the NF18MESH's
      if (isVelocity) {
        return hardware.filter(hw => hw.name.includes('NF18ACV'))
      }

      return hardware.filter(hw => hw.name.includes('NF18MESH'))
    }

    if (
      ['UNI', 'HIS', 'FUZ'].includes(this.brand) &&
      !['FTTN', 'FTTB', 'SAT'].includes(primaryNetwork.technology)
    ) {

      // filter further depending on plan speed, but you can also just order for now
      if (plan && plan.download >= 250) {
        return hardware.filter(hw => hw.name.includes('Beacon 3.1') || hw.name.includes('Beacon 2') || hw.name.includes('Beacon G6'))
      }
      return hardware.filter(hw => hw.name.includes('Beacon 3.1') || hw.name.includes('Beacon 1.1') || hw.name.includes('Beacon 2'))
    }

    return hardware.filter(hw => hw.name.includes('NF18MESH'))
  }

  planGroups(plans: Plan[]): string[] {
    const groups = []

    plans.map(plan => {
      if (!groups.includes(plan.catalogGroup)) {
        return groups.push(plan.catalogGroup)
      }
      return false
    })

    return groups
  }

  defaultPlan(primaryNetwork: Network, group: string = 'Residential', planGroup: string = null): Plan | null {
    let defaultPlan: Plan | null

    const plans = this.plans(primaryNetwork, null, group, planGroup)
      .filter(
        plan =>
          plan.carrierCode === primaryNetwork.carrierCode &&
          plan.download <= primaryNetwork.maxDownload
      )

    if (
      primaryNetwork.carrierCode !== 'SMP' &&
      primaryNetwork.carrierCode !== 'SM' &&
      group === 'Residential'
    ) {
      defaultPlan = plans.find(
        p =>
          p.download ===
          (plans.length > 1 &&
          primaryNetwork.maxDownload >= 100
            ? 100
            : primaryNetwork.maxDownload)
      )

      if (
        primaryNetwork.carrierCode === 'NB' &&
        ['FTTN', 'FTTB'].includes(primaryNetwork.technology)
      ) {
        defaultPlan = plans.find(p => p.download === 25)
      }
    } else {
      defaultPlan = plans.find(
        p =>
          p.quota ===
          (primaryNetwork.carrierCode === 'SM' ? 165 : 100)
      )
    }

    if (
      primaryNetwork.carrierCode !== 'SMP' &&
      primaryNetwork.carrierCode !== 'SM' &&
      group.includes('Business')
    ) {
      let filteredPlans = plans;
      if (planGroup) {
        filteredPlans = plans.filter(pl => pl.catalogGroup === planGroup)
      }
      defaultPlan = filteredPlans.find(x=>x!==undefined);
    }


    if (primaryNetwork.carrierCode === 'HW') {
      defaultPlan = plans.find(p => p.quota === 200)
    }

    if (primaryNetwork.carrierCode === 'HX') {
      defaultPlan = plans.find(p => p.quota === 0)
    }

    if (primaryNetwork.carrierCode === 'WIR') {
      defaultPlan = plans.find(p => p.download === 50)
    }

    if (primaryNetwork.carrierCode === 'UFL') {
      defaultPlan = plans.find(p => p.download === 50)
    }

    return defaultPlan
  }

  defaultHardware(primaryNetwork: Network, voiceInCart?: CartProduct[]): Hardware | null {
    const hardware = this.catalogWrapper.hardware()

    // Only show the 4G router if the technology selected is HWBB 4G
    if (primaryNetwork.carrierCode === 'HW') {
      return hardware.find(hw => hw.name === 'Netcomm NL1901ACV')
    }

    // Only show the 5G router if the technology selected is HWBB 4G
    if (primaryNetwork.carrierCode === 'HX') {
      return hardware.find(hw => hw.name === 'Nokia FastMile')
    }

    // if addon voip selected, show Netcomm despite tech conditions
    if (voiceInCart.length && voiceInCart[0].id) {
      return hardware.find(hw => hw.name === 'Netcomm NF18MESH Router')
    }

    if (
      ['UNI', 'HIS', 'FUZ'].includes(this.brand) &&
      !['FTTN', 'FTTB', 'SAT'].includes(primaryNetwork.technology)
    ) {
      return hardware.find(hw => hw.name === 'Nokia WiFi Beacon 2')
    }

    return hardware.find(hw => hw.name === 'Netcomm NF18MESH Router')
  }
}