export interface AddOn {
  id: string | null
  sku: string | null
  name: string | null
  description: object | null
  price: number | null
  localCalls: boolean
  nationalCalls: boolean
  oneEightCalls: boolean
  mobileCalls: boolean
  internationalCalls: boolean
  tagLine: string | null
  listed: boolean | null
}

export interface Address {
  id: number | null
  address1: string
  address2: string | null
  unit: string | null
  carrier: string | null
  number: string
  street: string
  suburb: string
  state: string
  country: string
  postcode: string
  gnaf: string | null
  lat: number | null
  lng: number | null
  address: string | null
  formatted: string
  links: object
  siteName: string | null
  developmentName: string | null
  serviceClass: string | null
}

export interface Cart {
  id: string | null
  price: number
  location: string
  technology: string | null
  salesPerson: string | null
  source: string | null
  complete: boolean
  dealer: string
  brand: string
  notes: string | null
  contractTerm: number | null
  products: CartProduct[] | null
  address: CartAddress | null
  customer: CartCustomer | null
  paymentType: string
  creditCard: CartCreditCard | null
  bankAccount: CartBankAccount | null
  creditCheck: CartCreditCheck | null
  termAccepted: boolean | null
  privacyAccepted: boolean | null
  directDebitAccepted: boolean | null
  csgWaiverAccepted: boolean | null
  suggestedPromo: object | null
  activePromo: any | null
  filteredPromos: any | null
  promoPrices: any | null
  relocation: CartRelocation | null
  newService: CartNewService | null
  partner: string | null
  promodeal: string | null
  staticIp: Boolean | null
  activationDate: string | null
  activationDateOption: string | null
  needsModem: Boolean | null
  summaryPromo: string | null
  listed: Boolean | null
  ticketId: string | null
  cartError: CartError | null
  utmData: Object | null
  suggestedPromoMinContract: number | null
  suggestedPromoMinDownload: number | null
  preorder: boolean | null
  voipOnly: boolean | null
  memberId: string | null
  voipPorting: CartVoip | null
  voipPortingAccepted: boolean | null
  voipPortingAgreement: boolean | null
  voipPortingAgreement2: boolean | null
  coolingOffPeriod: boolean | null
  contractCost: number | null
}

export interface CartProduct {
  id: number | string | null
  name: string
  price: number
  code?: string | null
  term?: number | null
  symbillId: string | null
  type: string
  actionDuration?: number | null
  download?: number | null
  actionStart?: number | null,
  catalogGroup?: string | null
}

export interface CartError {
  error: boolean | null
  fieldErrors: CartFieldError[] | null
}

export interface CartFieldError {
  errorMessage: string | null
  fieldId: string | number | null
}

export interface CartAddress {
  id: string
  address: string
  addressConfirmed: boolean | null
  building: string
  carrier: string
  carrierId: string
  developmentName: string | null
  gnaf: string | null
  levelNumber: string | null
  levelType: string | null
  lotNumber: string | null
  network: CartNetwork | null
  ndc: number | null
  ncc: number | null
  postcode: string | null
  state: string
  streetName: string
  streetNumber: string
  streetSuffix: string | null
  streetType: string
  suburb: string
  technology: string
  topology: string
  unitNumber: string | null
  unitType: string | null
  powerType: string | null
  buildingHeight: string | null
  roofType: string | null
  buildingMaterials: string | null
  street1: string | null
  street2: string | null
  ntdLocation: string | null
  velocityStatus: string | null
  serviceClass: string | null
  expectedActivationDate: string | null
  isVelocity: boolean 
}

export interface CartRelocation {
  status: boolean
  oldAccountNumber: string | null
  oldAddress: string | null
  oldServiceId: string | null
}

export interface CartNewService {
  status: boolean
  oldAccountNumber: string | null
}

export interface CartCustomer {
  firstName: string
  lastName: string
  email: string
  phone: string
  businessName: string
  abn: string
  shippingAddress: ShippingAddress
  shippingAddressConfirmed: boolean | null
  referralCode: string | null
}

export interface ShippingAddress {
  postcode: string | null
  state: string | null
  streetName: string | null
  streetNumber: string | null
  streetSuffix: string | null
  streetType: string | null
  suburb: string | null
  street1: string | null
  street2: string | null
}

export interface CartCreditCard {
  firstName: string
  lastName: string
  number: string
  expiryMonth: string
  expiryYear: string
  cvv: string
  token: string
  provider: string
}

export interface CartBankAccount {
  customerName: string
  accountNumber: string
  bsb: string,
  token: string
}

export interface CartCreditCheck {
  birthdate: string
  licenseId: string
}

export interface CartNetwork {
  carrierCode: string
  carrierId: string
  id: number
  identifier: string
  maxDownload: number
  maxUpload: number
  minDownload: number
  minUpload: number
  name: string
  ndc?: number
  priority: number
}

export interface CartVoip {
  existingFnn: string
  phoneProvider: string
  phoneAccountName: string
  phoneAccountNumber: string
}

export interface Application {
  id: string
  address: Address
  addOns: AddOn[]
  products: Product[]
  contract: ApplicationContract
  contact: ApplicationContact
  creditCard: CreditCard
  plan: Plan | null
  network: Network
  activationFee: number | null
  promo: object | null
  promoValid: boolean
  promotionCode: string
  promoPrice: number | null
  ongoingPromoPrice: number | null
  referral: string | null
  locationId: string | null
  ndc: number | null
  ndcDiscount: number | null
  download: number | null
  manualBilling: boolean
  notes: string | null
  minHeight: number | null
  ticketId: string | null
  technology: string | null
  currentUser: AuthenticatedUser
  salesUser: AuthenticatedUser
  satelliteData: SatelliteInfo
  relocation: boolean
  relocationDate: string | null
  relocationOption: string | null
  relocationSymbillID: string | null
  relocationOldAddress: string | null
  partner: string | null
  directDebitAccepted: boolean | null
  voiceListed: boolean | null
  needsModem: boolean | null
}

export interface SatelliteInfo {
  powerSourceType: string | null
  buildingHeight: string | null
  roofType: string | null
  buildingWall: string | null
}
export interface AuthenticatedUser {
  id: number | null
  name: string | null
  email: string | null
}

export interface ApplicationContact {
  firstName: string | null
  lastName: string | null
  email: string | null
  phone: string | null
  businessName: string | null
  abn: string | null
  shippingAddress: string | null
  confirmShippingAddress: boolean
  confirmServiceAddress: boolean
}

export interface ApplicationContract {
  length: number
  accepted: boolean
  document: string | null
}

export interface CreditCard {
  firstName: string
  lastName: string
  number: string
  expiryMonth: string
  expiryYear: string
  cvv: string
}

export interface Network {
  id: number | null
  name: string | null
  identifier: string | null
  carrierCode: string | null
  priority: number | null
  maxDownload: number | null
  maxUpload: number | null
  suites: Suite[] | []
  ndc?: number | null
  ncc?: number | null
  alternateTechnology?: string
  alternateTechnologyStatus?: string
  alternateTechnologyDate?: Date,
  technology?: string | null
}

export interface ServiceQualification {
  address: Address
  networks: Network[] | []
  defaultNetworks: Network[] | []
  id: string | null
  isAvailable: boolean
  canProvision: boolean
  isPreOrder: boolean
  links: object
  notes: string
  type: string
  technology: string
  locationId: string | null
  locations: Location[] | []
  ndc: number | null
  ncc: number | null
  estate: string | null
  identifier: string | null
}

export interface Catalog {
  attachment?: Attachment | null
  catalogType: string | null
  catalogGroup?: string | null
  categoryType?: string | null
  category: Category | null
  description: string | null
  id: string | null
  href: string | null
  lastUpdate: string | null
  lifecycleStatus: string | null
  name: string | null
  relatedParty: RelatedParty | null
  validFor: TimePeriod | null
  version: string | null
}

export interface CatalogFee {
  id: string | null
  name: string | null
  description: string | null
  categoryType: string | null
  catalogType: string | null
}

export interface ProductOffering {
  name: string | null,
  description: string | null,
  isBundle: boolean | null,
  isSellable: boolean | null,
  lastUpdate: string | null,
  lifecycleStatus: string | null,
  statusReason: string | null,
  version: string | null,
  validFor: TimePeriod,
  productSpecification: ProductSpecification | null,
  productOfferingPrice: ProductOfferingPrice[],
  prodSpecCharValueUse: ProdSpecCharValueUse[],
  id: string,
  '@type': string,
  '@schemaLocation': string,
  href: string,
  '@baseType': string
}

export interface Category {
  description: string | null
  id: string | null
  name: string | null
  parentId: string | null
  plans?: Plan[] | []
  validFor: TimePeriod | null,
  productOffering: ProductOffering[],
}

export interface Plan {
  name: string
  description: string | null
  isBundle?: boolean
  isSellable: boolean
  lifecycleStatus?: string,
  version: string
  validFor: TimePeriod
  productSpecification: ProductSpecification
  productOfferingPrice: ProductOfferingPrice[] | []
  prodSpecCharValueUse: ProdSpecCharValueUse[] | []
  '@type'?: string,
  '@schemaLocation'?: string,
  href?: string,
  '@baseType'?: string,
  id: string,
  symbillId?: string | null,
  displayName?: string | null,
  download: number
  upload: number
  quota: number
  price: number
  carrierCode: string | null
  identifier?: string | null,
  term?: number
  type?: string,
  quotaPeak?: number | null,
  quotaOffPeak?: number | null
  typicalEveningSpeed?: number | null
  statusReason: string
  suite: Suite
  activationFee: number
  businessFee: number,
  code?: string | null
  orderId?: string | null
  image?: string[],
  catalogGroup?: string | null
  hardwareMatch?: string[] | null
}

export interface Hardware {
  name: string
  description: string | null
  isBundle: boolean
  isSellable: boolean
  lifecycleStatus?: string,
  version: string
  validFor: TimePeriod
  productSpecification: ProductSpecification
  productOfferingPrice: ProductOfferingPrice[] | []
  prodSpecCharValueUse: ProdSpecCharValueUse[] | []
  '@type': string,
  '@schemaLocation'?: string,
  href: string,
  '@baseType': string,
  id: string,
  symbillId: string | null,
  displayName: string | null,
  price: number
  carrierCode: string | null
  identifier?: string | null,
  term: number
  type: string,
  statusReason: string
  suite?: Suite | null
  activationFee: number
  code: string | null
  orderId: string | null
  image: string[],
  catalogGroup?: string | null
}

export interface Addon {
  name: string
  description: string | null
  isBundle: boolean
  isSellable: boolean
  lifecycleStatus?: string,
  version: string
  validFor: TimePeriod
  productSpecification: ProductSpecification
  productOfferingPrice: ProductOfferingPrice[] | []
  prodSpecCharValueUse: ProdSpecCharValueUse[] | []
  '@type': string,
  '@schemaLocation'?: string,
  href: string,
  '@baseType': string,
  id: string,
  price: number
  type: string,
  statusReason: string
  code: string | null
  recurrence: string | null
}

export interface Suite {
  id: string
  name: string
  type: string
  technology: string
  carrierCode: string
}

export interface ProductOfferingPrice {
  id: string
  name: string
  price: Money
}

export interface ProductSpecification {
  brand: string
  description: string
  name: string
  isBundle: boolean
  lastUpdate: string
  productNumber: string
  version: string
  validFor: TimePeriod
  id: string
  href: string
}

export interface ProdSpecCharValueUse {
  description: string
  name: string
  valueType: string
  productSpecCharacteristicValue: ProductSpecCharacteristicValue[] | []
}

export interface ProductSpecCharacteristicValue {
  isDefault: boolean
  valueType: string
  value: unknown
}

export interface Money {
  amount: number | null
  unit: string | null
}

export interface Attachment {
  id: string
  attachmentType: string
  name: string
  url: string
}

export interface RelatedParty {
  id: string
  name: string
  role: string
}

export interface TimePeriod {
  startDateTime: string
  endDateTime: string | null
}

export interface Phone {
  id: number
  name: string
  price: number
  contractLength: number
  unTimed: number
  flagFall: number
  auPerMin: number
  auFlagFall: number
  onThreeCalls: number
  intPerMin: number
  intFlagFall: number
  tagLine: string
  localCalls: boolean
  nationalCalls: boolean
  oneEightCalls: boolean
  mobileCalls: boolean
  internationalCalls: boolean
}



export interface ReviewFuze {
  id: number
  body: string
  name: string
}

export interface Product {
  id: string | null
  name: string | null
  description: string | null
  price: number | null
  image: object | null
  link: string | null
  order: number | null
  type: string | null
}

export interface Location {
  networkIdentifier: string | null
  locationId: string | null
  ndc: number | null
  technology: string | null
  isAvailable: boolean
  canProvision: boolean
  rtcDate: Date | null
  isPreOrder: boolean
}

export interface Outage {
  title: string | null
  dateStarted: string | null
  dateResolved: string | null
  announcements: Announcement | null
}

export interface Announcement {
  title: string | null
  body: string | null
}

export interface Discount {
  actionType: string,
  actionValue: number,
  actionObjectId: string,
  actionDuration: number | null,
  actionApplicableTo: string[] | null,
  applicableWhen: string[] | null,
  actionCategory: string,
  discountDescription: string | null
  name: string | null
  price: number,
  term?: number | null,  
  id: string,
  code: string,
  symbillId: string,
  type: string,
  actionStart?: number | null
}

export interface CartPromo {
  code: string | null,
  description: string,
  discounts: Discount[],
  carriers: string[],
  developments: string[],
  suburbs: string[],
  atl: boolean,
  id: string,
  validFor: TimePeriod,
  error: string,
  isValid: boolean,
  minContract: number,
  minDownload: number,
  maxDownload: number,
  type: string,
  plans: string[],
  hiddenPlans: string[],
  partners: string[],
  ntdLocation: string,
  velocityStatus: string[],
  catalogGroup?: string[],
  shouldDisplay?: boolean
}

export enum Carrier {
  OptiComm = 'OC',
  LBNCo = 'LB',
  NBN = 'NB',
  OptusNBN = 'OF',
  OPENetworks = 'ON',
  HWBB = 'HW',
  HXBB = 'HX',
  UnitiFixedWireless = 'UW',
  UnitiFixedLine = 'UFL',
  Redtrain = 'RT',
  NBNWireless = 'WIR',
  Skymuster = 'SAT',
  SkymusterPlus = 'SMP'
}

export interface OutageData {
  id: string | null
  title: string | null
  network: string | null
  issue: string | null
  status: string | null
  plannedEndAt: string | null
}

export interface Outages {
  currentOutages: OutageData[] | null
  scheduledOutages: OutageData[] | null
}
