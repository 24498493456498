import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _aea7636a = () => interopDefault(import('../pages/about-us/index.vue' /* webpackChunkName: "pages/about-us/index" */))
const _49144a75 = () => interopDefault(import('../pages/build-to-rent/index.vue' /* webpackChunkName: "pages/build-to-rent/index" */))
const _042064fc = () => interopDefault(import('../pages/building-management-program/index.vue' /* webpackChunkName: "pages/building-management-program/index" */))
const _e828e2a6 = () => interopDefault(import('../pages/business/index.vue' /* webpackChunkName: "pages/business/index" */))
const _77e6c952 = () => interopDefault(import('../pages/business-fibre/index.vue' /* webpackChunkName: "pages/business-fibre/index" */))
const _0f3004d0 = () => interopDefault(import('../pages/compare-broadband/index.vue' /* webpackChunkName: "pages/compare-broadband/index" */))
const _1ef4eeea = () => interopDefault(import('../pages/compare-connect/index.vue' /* webpackChunkName: "pages/compare-connect/index" */))
const _53a3ccd6 = () => interopDefault(import('../pages/complaints/index.vue' /* webpackChunkName: "pages/complaints/index" */))
const _1d238929 = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _6093c002 = () => interopDefault(import('../pages/legal/index.vue' /* webpackChunkName: "pages/legal/index" */))
const _24a77294 = () => interopDefault(import('../pages/lendlease/index.vue' /* webpackChunkName: "pages/lendlease/index" */))
const _16db93d7 = () => interopDefault(import('../pages/migration/index.vue' /* webpackChunkName: "pages/migration/index" */))
const _78ba0b6b = () => interopDefault(import('../pages/nokia/index.vue' /* webpackChunkName: "pages/nokia/index" */))
const _d0772baa = () => interopDefault(import('../pages/outages/index.vue' /* webpackChunkName: "pages/outages/index" */))
const _33777c77 = () => interopDefault(import('../pages/phone/index.vue' /* webpackChunkName: "pages/phone/index" */))
const _5773e31a = () => interopDefault(import('../pages/plans/index.vue' /* webpackChunkName: "pages/plans/index" */))
const _21af7d9b = () => interopDefault(import('../pages/raa/index.vue' /* webpackChunkName: "pages/raa/index" */))
const _188f82f6 = () => interopDefault(import('../pages/signup/index.vue' /* webpackChunkName: "pages/signup/index" */))
const _1e520aef = () => interopDefault(import('../pages/static-ip/index.vue' /* webpackChunkName: "pages/static-ip/index" */))
const _576d0b8a = () => interopDefault(import('../pages/velocity/index.vue' /* webpackChunkName: "pages/velocity/index" */))
const _6c75c34e = () => interopDefault(import('../pages/white-label/index.vue' /* webpackChunkName: "pages/white-label/index" */))
const _7525eb8b = () => interopDefault(import('../pages/build-to-rent/customer.vue' /* webpackChunkName: "pages/build-to-rent/customer" */))
const _1bd9d396 = () => interopDefault(import('../pages/internet/broadband/index.vue' /* webpackChunkName: "pages/internet/broadband/index" */))
const _d64336f8 = () => interopDefault(import('../pages/internet/fixed-line/index.vue' /* webpackChunkName: "pages/internet/fixed-line/index" */))
const _7f306eb6 = () => interopDefault(import('../pages/internet/fixed-wireless-residential/index.vue' /* webpackChunkName: "pages/internet/fixed-wireless-residential/index" */))
const _7727b459 = () => interopDefault(import('../pages/internet/nbn/index.vue' /* webpackChunkName: "pages/internet/nbn/index" */))
const _31c0373e = () => interopDefault(import('../pages/internet/nbn-fixed-wireless/index.vue' /* webpackChunkName: "pages/internet/nbn-fixed-wireless/index" */))
const _5966628e = () => interopDefault(import('../pages/internet/opticomm/index.vue' /* webpackChunkName: "pages/internet/opticomm/index" */))
const _26e808ec = () => interopDefault(import('../pages/internet/uniti-fixed-line/index.vue' /* webpackChunkName: "pages/internet/uniti-fixed-line/index" */))
const _616dc4d4 = () => interopDefault(import('../pages/internet/uniti-fixed-wireless/index.vue' /* webpackChunkName: "pages/internet/uniti-fixed-wireless/index" */))
const _90d4dfe8 = () => interopDefault(import('../pages/legal/legacy-cis/index.vue' /* webpackChunkName: "pages/legal/legacy-cis/index" */))
const _0206af46 = () => interopDefault(import('../pages/legal/legacy-terms/index.vue' /* webpackChunkName: "pages/legal/legacy-terms/index" */))
const _791935d3 = () => interopDefault(import('../pages/migration/fuz/index.vue' /* webpackChunkName: "pages/migration/fuz/index" */))
const _06758954 = () => interopDefault(import('../pages/migration/nbn-abb/index.vue' /* webpackChunkName: "pages/migration/nbn-abb/index" */))
const _5e7cc19e = () => interopDefault(import('../pages/migration/nbn-referral/index.vue' /* webpackChunkName: "pages/migration/nbn-referral/index" */))
const _e768c6d4 = () => interopDefault(import('../pages/migration/skymesh/index.vue' /* webpackChunkName: "pages/migration/skymesh/index" */))
const _59bb32f8 = () => interopDefault(import('../pages/migration/uni/index.vue' /* webpackChunkName: "pages/migration/uni/index" */))
const _42970d34 = () => interopDefault(import('../pages/signup/confirm-order.vue' /* webpackChunkName: "pages/signup/confirm-order" */))
const _ed52840a = () => interopDefault(import('../pages/signup/new-service.vue' /* webpackChunkName: "pages/signup/new-service" */))
const _37a40645 = () => interopDefault(import('../pages/signup/relocation.vue' /* webpackChunkName: "pages/signup/relocation" */))
const _08f7ed80 = () => interopDefault(import('../pages/signup/voip/index.vue' /* webpackChunkName: "pages/signup/voip/index" */))
const _556b6356 = () => interopDefault(import('../pages/signup/welcome.vue' /* webpackChunkName: "pages/signup/welcome" */))
const _6353f64a = () => interopDefault(import('../pages/deals/_code.vue' /* webpackChunkName: "pages/deals/_code" */))
const _4b4340cb = () => interopDefault(import('../pages/partner/_code.vue' /* webpackChunkName: "pages/partner/_code" */))
const _723bc766 = () => interopDefault(import('../pages/promotion/_code.vue' /* webpackChunkName: "pages/promotion/_code" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _aea7636a,
    name: "about-us"
  }, {
    path: "/build-to-rent",
    component: _49144a75,
    name: "build-to-rent"
  }, {
    path: "/building-management-program",
    component: _042064fc,
    name: "building-management-program"
  }, {
    path: "/business",
    component: _e828e2a6,
    name: "business"
  }, {
    path: "/business-fibre",
    component: _77e6c952,
    name: "business-fibre"
  }, {
    path: "/compare-broadband",
    component: _0f3004d0,
    name: "compare-broadband"
  }, {
    path: "/compare-connect",
    component: _1ef4eeea,
    name: "compare-connect"
  }, {
    path: "/complaints",
    component: _53a3ccd6,
    name: "complaints"
  }, {
    path: "/contact",
    component: _1d238929,
    name: "contact"
  }, {
    path: "/legal",
    component: _6093c002,
    name: "legal"
  }, {
    path: "/lendlease",
    component: _24a77294,
    name: "lendlease"
  }, {
    path: "/migration",
    component: _16db93d7,
    name: "migration"
  }, {
    path: "/nokia",
    component: _78ba0b6b,
    name: "nokia"
  }, {
    path: "/outages",
    component: _d0772baa,
    name: "outages"
  }, {
    path: "/phone",
    component: _33777c77,
    name: "phone"
  }, {
    path: "/plans",
    component: _5773e31a,
    name: "plans"
  }, {
    path: "/raa",
    component: _21af7d9b,
    name: "raa"
  }, {
    path: "/signup",
    component: _188f82f6,
    name: "signup"
  }, {
    path: "/static-ip",
    component: _1e520aef,
    name: "static-ip"
  }, {
    path: "/velocity",
    component: _576d0b8a,
    name: "velocity"
  }, {
    path: "/white-label",
    component: _6c75c34e,
    name: "white-label"
  }, {
    path: "/build-to-rent/customer",
    component: _7525eb8b,
    name: "build-to-rent-customer"
  }, {
    path: "/internet/broadband",
    component: _1bd9d396,
    name: "internet-broadband"
  }, {
    path: "/internet/fixed-line",
    component: _d64336f8,
    name: "internet-fixed-line"
  }, {
    path: "/internet/fixed-wireless-residential",
    component: _7f306eb6,
    name: "internet-fixed-wireless-residential"
  }, {
    path: "/internet/nbn",
    component: _7727b459,
    name: "internet-nbn"
  }, {
    path: "/internet/nbn-fixed-wireless",
    component: _31c0373e,
    name: "internet-nbn-fixed-wireless"
  }, {
    path: "/internet/opticomm",
    component: _5966628e,
    name: "internet-opticomm"
  }, {
    path: "/internet/uniti-fixed-line",
    component: _26e808ec,
    name: "internet-uniti-fixed-line"
  }, {
    path: "/internet/uniti-fixed-wireless",
    component: _616dc4d4,
    name: "internet-uniti-fixed-wireless"
  }, {
    path: "/legal/legacy-cis",
    component: _90d4dfe8,
    name: "legal-legacy-cis"
  }, {
    path: "/legal/legacy-terms",
    component: _0206af46,
    name: "legal-legacy-terms"
  }, {
    path: "/migration/fuz",
    component: _791935d3,
    name: "migration-fuz"
  }, {
    path: "/migration/nbn-abb",
    component: _06758954,
    name: "migration-nbn-abb"
  }, {
    path: "/migration/nbn-referral",
    component: _5e7cc19e,
    name: "migration-nbn-referral"
  }, {
    path: "/migration/skymesh",
    component: _e768c6d4,
    name: "migration-skymesh"
  }, {
    path: "/migration/uni",
    component: _59bb32f8,
    name: "migration-uni"
  }, {
    path: "/signup/confirm-order",
    component: _42970d34,
    name: "signup-confirm-order"
  }, {
    path: "/signup/new-service",
    component: _ed52840a,
    name: "signup-new-service"
  }, {
    path: "/signup/relocation",
    component: _37a40645,
    name: "signup-relocation"
  }, {
    path: "/signup/voip",
    component: _08f7ed80,
    name: "signup-voip"
  }, {
    path: "/signup/welcome",
    component: _556b6356,
    name: "signup-welcome"
  }, {
    path: "/deals/:code?",
    component: _6353f64a,
    name: "deals-code"
  }, {
    path: "/partner/:code?",
    component: _4b4340cb,
    name: "partner-code"
  }, {
    path: "/promotion/:code?",
    component: _723bc766,
    name: "promotion-code"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
