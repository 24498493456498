import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { HttpClientInterface, HttpError } from './HttpClientInterface';

export default class AxiosHttpClient implements HttpClientInterface {
  private options: AxiosRequestConfig = {
    baseURL: '',
  };

  constructor (baseUrl: string) {
    this.options.baseURL = baseUrl;
  }

  public async get<T> (url: string, options: AxiosRequestConfig = {}): Promise<T> {
    const request = this.prepareRequest<T>(options)

    return this.wrapRequest(async () => {
      const response = await axios.get<unknown, AxiosResponse<T>>(url, request);
      return response.data;
    });
  }

  public async post<T> (url: string, data: Record<string, unknown>, options: AxiosRequestConfig = {}): Promise<T> {
    const request = this.prepareRequest<T>(options)

    return this.wrapRequest(async () => {
      const response = await axios.post<unknown, AxiosResponse<T>>(url, data, request);
      return response.data;
    });
  }

  public async put<T> (url: string, data: Record<string, unknown>, options: AxiosRequestConfig = {}): Promise<T> {
    const request = this.prepareRequest<T>(options)

    return this.wrapRequest<T>(async () => {
      const response = await axios.put<unknown, AxiosResponse<T>>(url, data, request);
      return response.data;
    });
  }

  // eslint-disable-next-line class-methods-use-this
  private async wrapRequest<T> (fn: () => Promise<T>): Promise<T> {
    try {
      return await fn();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new HttpError(error.message);
      } else {
        throw error;
      }
    }
  }

  private prepareRequest<T>(options: AxiosRequestConfig<T> = {}): AxiosRequestConfig<T> {
    return { ...options, ...this.options, headers: { ...options.headers, 'Content-Type': 'application/json' } };
  }
}
