import { GetterTree } from 'vuex'
import { RootState } from 'store'
import FaqProviderBuilder from '~/lib/faq/FaqProviderBuilder'
import { Faq } from '~/lib/faq/FaqProviderInterface'

export const name = 'faq'
export const namespaced = true

export interface State {}

export const getters: GetterTree<State, RootState> = {
  byType: state => (type: string): Faq[] => FaqProviderBuilder.getProvider().getFaqsByType(type)
}
