import { RootState } from 'store'
import { ActionTree, MutationTree, GetterTree } from 'vuex'

import { outage } from '~/store/api'
import { Outages } from '~/store/interfaces'

export const name = 'outages'

export const namespaced = true

export interface State {
  outages: Array<Outages> | null,
}

export const state = (): State => ({
  outages: [],
})

export const getters: GetterTree<State, RootState> = {
  outages: state => state.outages,
}

export interface Actions<S, R> extends ActionTree<S, R> {}

export const actions: Actions<State, RootState> = {

  // Opticomm Outages Data
  async fetchOutages({ commit }) {
    try {
      await outage.axios(`${outage.apiUrl}/outage-list`).then(async response => {
        const outageData = response.data
        commit('setOutages', outageData)
      })
    } catch (e) {
      console.log('Error getting outages: ', e)
    }
  },
}

export const mutations: MutationTree<State> = {
  setOutages(state: State, outages: Array<Outages> | null) {
    state.outages = outages
  },
}