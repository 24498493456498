export class HttpError extends Error { }

export interface HttpClientInterface {

  /**
   * Makes a GET request.
   *
   * @throws HttpError
   *
   * @param url
   * @param options
   */
  get<T>(url: string, options?: Record<string, unknown>): Promise<T>;

  /**
   * Makes a GET request.
   *
   * @throws HttpError
   *
   * @param url
   * @param data
   * @param options
   */
  post<T>(url: string, data: Record<string, unknown>, options?: Record<string, unknown>): Promise<T>;

  /**
   * Makes a GET request.
   *
   * @throws HttpError
   *
   * @param url
   * @param data
   * @param options
   */
  put<T>(url: string, data: Record<string, unknown>, options?: Record<string, unknown>): Promise<T>;
}